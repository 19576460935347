import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {SurgeryDataModelService} from '../data-models/surgery-data-model.service';
import {AppConstants} from '../../constants/app-constants';
import {DentnetCommunicatorService} from '../services/dentnet-communicator.service';
import {RequestTimeService} from '../services/request-time.service';

@Injectable()
export class DentnetIndexResolverService implements Resolve<any> {

  constructor(private communicator: DentnetCommunicatorService, private dataModel: SurgeryDataModelService, public requestTimeService: RequestTimeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.requestTimeService.calcDiff(this.dataModel.maxSecondsBetween, this.dataModel.lastRequest)
    ) {
      this.dataModel.lastRequest = Date.now();
      return this.communicator.index(AppConstants.SURGERIES, this.dataModel);
    }
    return true;
  }

}
