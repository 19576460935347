import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Image} from '../classes/image';

@Pipe({
  name: 'ordering',
  pure: false
})
export class OrderingPipe implements PipeTransform {

  transform(values: Array<Image>): any {
    if (isNullOrUndefined(values)) {
      return [];
    }
    return values.sort((a, b) => {
      return a.ordering - b.ordering;
    });
  }

}
