import {TableRow} from './table-row';

export class Message extends TableRow{

  private _text: string;
  private _type: string;

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

}
