import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription, Subject, BehaviorSubject} from 'rxjs';
import {KzvCsvDataModelService} from '../data-models/kzv-csv-data-model.service';
import {AppConstants} from '../../constants/app-constants';
import {IImportStatistics} from '../sites/kzv-import/import-statistics/import-statistics';

const KZV_IMPORT_STATUS_AUTOUPDATE = 0;
const KZV_IMPORT_STATUS_AUTOADDPARKINGLIST = 1;
const KZV_IMPORT_STATUS_MANUALCOMPARE = 2;
const KZV_ADDRESSES_IMPORT_STATUS_MANUALCOMPARE_UPDATE = 3;
const KZV_ADDRESSES_IMPORT_STATUS_ISIMEX = 4;
const KZV_ADDRESSES_IMPORT_STATUS_MANUALCOMPARE_DENTIST = 5;
const KZV_ADDRESSES_IMPORT_STATUS_ISIMPORTED = 6;

@Injectable()
export class KzvService implements OnDestroy {

  private _manualCompare: BehaviorSubject<any> = new BehaviorSubject(null);


  get manualCompare(): BehaviorSubject<any> {
    return this._manualCompare;
  }


  private _activeItem: BehaviorSubject<any> = new BehaviorSubject(null);
  get activeItem(): BehaviorSubject<any> {
    return this._activeItem;
  }

  private _isComplete = false;


  get isComplete(): boolean {
    return this._isComplete;
  }

  set isComplete(value: boolean) {
    this._isComplete = value;
  }

  private _isActive = false;

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  private ambulance;

  private subscription: Subscription;

  private headers: HttpHeaders;

  public importStatistics: IImportStatistics = {
    overall: new BehaviorSubject(0),
    processed: new BehaviorSubject(0),
    addedToParkingList: new BehaviorSubject(0),
    updatedAutomatically: new BehaviorSubject(0),
    compareManually: new BehaviorSubject(0),
    dismissedManually: new BehaviorSubject(0),
    isImex: new BehaviorSubject(0),
  };


  constructor(private $http: HttpClient, public kzvCsv: KzvCsvDataModelService) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    this.activeItem.subscribe((next) => {
      if (next) {
        this.postItem(next);
      }
    });
  }


  public postItem(item) {
    this.$http.post(AppConstants.SERVICE + '/kzvimport/ambulances/import', item, {
      'headers': this.headers
    }).subscribe((response: IKzvResponse) => {

      switch (response.importStatus) {
        case (KZV_IMPORT_STATUS_AUTOUPDATE):
          this.importStatistics.updatedAutomatically.next(this.importStatistics.updatedAutomatically.getValue() + 1);
          this.kzvCsv.addMessage(response.message);
          this.startImport();
          break;
        case (KZV_IMPORT_STATUS_AUTOADDPARKINGLIST):
          this.importStatistics.addedToParkingList.next(this.importStatistics.addedToParkingList.getValue() + 1);
          this.kzvCsv.addMessage(response.message);
          this.startImport();
          break;
        case (KZV_ADDRESSES_IMPORT_STATUS_ISIMEX):
          this.importStatistics.isImex.next(this.importStatistics.isImex.getValue() + 1);
          this.kzvCsv.addMessage(response.message);
          this.startImport();
          break;
        case (KZV_IMPORT_STATUS_MANUALCOMPARE):
          this.isActive = false;
          this.manualCompare.next(response.data);
          this.importStatistics.compareManually.next(this.importStatistics.compareManually.getValue() + 1);
          break;
        case (KZV_ADDRESSES_IMPORT_STATUS_MANUALCOMPARE_DENTIST):
          this.isActive = false;
          this.manualCompare.next(response.data);
          this.importStatistics.compareManually.next(this.importStatistics.compareManually.getValue() + 1);
          break;
        case (KZV_ADDRESSES_IMPORT_STATUS_ISIMPORTED):
          this.kzvCsv.addMessage(response.message);
          this.startImport();
          break;
        default:

          break;
      }
      this.importStatistics.processed.next(this.importStatistics.processed.getValue() + 1);
    });
  }

  public updateItem() {
    const item = this.activeItem.getValue();
    item.id = this.manualCompare.getValue().id;
    let streetnumber = '';
    if (this.activeItem.getValue().streetnumber) {
      streetnumber = this.activeItem.getValue().streetnumber;
    }
    item.street = this.activeItem.getValue().street + ' ' + streetnumber;
    Object.keys(item).forEach((key) => (item[key] == null) && delete item[key]);

    this.$http.put(AppConstants.SERVICE + '/kzvimport/ambulances/' + item.id, item, {
      'headers': this.headers
    }).subscribe((response: IKzvResponse) => {

      this.kzvCsv.addMessage(response.message);
      this.startImport();

    });
  }

  /**
   * if user chooses to add an item manualy in compare dialog
   */
  public addItemToParkingList() {
    const item = this.activeItem.getValue();
    item.addtoparking = true;
    Object.keys(item).forEach((key) => (item[key] == null) && delete item[key]);
    this.$http.post(AppConstants.SERVICE + 'kzvimport/ambulances/import', item, {
      'headers': this.headers
    }).subscribe((response: IKzvResponse) => {

      this.kzvCsv.addMessage(response.message);
      this.startImport();

    });
  }


  update() {
    console.log(this.manualCompare.getValue());
    const ambulance = this.manualCompare.getValue();
    this.$http.put(AppConstants.SERVICE + 'kzvimport/ambulances/' + this.manualCompare.getValue().id, ambulance, {
      'headers': this.headers
    }).subscribe((response: IKzvResponse) => {

      this.kzvCsv.addMessage(response.message);
      this.startImport();

    });
  }


  startImport() {
    if (this.kzvCsv.mappedItems.length > 0) {
      this.isActive = true;
      this.activeItem.next(this.kzvCsv.mappedItems.shift());
    } else {
      this.isActive = false;
      this.isComplete = true;

      console.log('IS COMPLETE ', this.isComplete);
    }
  }

  ngOnDestroy() {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }
}

interface IKzvResponse {
  message?: string;
  status: number;
  data?: object;
  importStatus?: number;

}
