import {Pipe, PipeTransform} from '@angular/core';
import {FilterList} from '../classes/filter-list';
import {Ambulanceview} from '../classes/ambulanceview';

@Pipe({
  name: 'status',
  pure: false
})
export class StatusPipe implements PipeTransform {

  transform(values: Array<Ambulanceview>, args: FilterList): any {
    // return values;

    return values.filter((value) => {
      if (args.isdeleted && value.isdeleted === 1) {
        return value;
      }
      if (args.isblacklist && value.isblacklist === 1) {
        return value;
      }
      if (args.isimex && value.dentnetstatus !== 1 && value.imexnumber !== '0' && value.imexnumber !== null && value.imexnumber !== '') {
        return value;
      }
      if (args.nocustomer && value.imexnumber === '0' || value.imexnumber === '' && args.nocustomer) {
        return value;
      }
      if (args.isdentnet && value.dentnetstatus === 1) {
        return value;
      }
      if (args.isdentnetpro && value.isdentnetpro) {
        return value;
      }
      if (args.isauthorized && value.isauthorized === 0) {
        return value;
      }
      if (!args.isactive && value.isactive === 0) {
        return value;
      }
    });
  }

}
