import {TableRow} from './table-row';

export class Upload extends TableRow {


  private _path: string;
  private _filename: string;
  private _itemname: string;
  private _ordering: number;
  private _type: number;
  private _file: string;
  private _uri: string;

  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get ordering(): number {
    return this._ordering;
  }

  set ordering(value: number) {
    this._ordering = value;
  }

  get itemname(): string {
    return this._itemname;
  }

  set itemname(value: string) {
    this._itemname = value;
  }

  get filename(): string {
    return this._filename;
  }

  set filename(value: string) {
    this._filename = value;
  }

  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }

  get file(): string {
    return this._file;
  }

  set file(value: string) {
    this._file = value;
  }

  get uri(): string {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value;
  }
}
