import {Component, OnInit} from '@angular/core';

import {BaseComponent} from '../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {AppConstants} from '../../../constants/app-constants';
import {AmbulanceviewDataModelService} from '../../data-models/ambulancesview-data-model.service';
import {CommunicatorService} from '../../services/communicator.service';
import {RequestTimeService} from '../../services/request-time.service';
import {FilterService} from '../../services/filter.service';
import {Ambulanceview} from '../../classes/ambulanceview';

@Component({
  selector: 'app-ambulances',
  templateUrl: './ambulances.component.html',
  styleUrls: ['./ambulances.component.scss']
})
export class AmbulancesComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.AMBULANCES_VIEW;
  private _sortitem: string;
  private _search: string;
  private _sortdirection = 'asc';
  private _time: number;
  public pageItems: Array<Ambulanceview> = [];
  public filterObject = [
    {key: 'nocustomer', value: false, wording: 'kein Kunde'},
    {key: 'isimex', value: '', wording: 'Imex'},
    {key: 'isdentnet', value: false, wording: 'Dent-net'},
    {key: 'isdentnetpro', value: false, wording: 'Dent-net Pro'},
    {key: 'isactive', value: false, wording: 'Ist Aktiv'},
    {key: 'isauthorized', value: false, wording: 'Ist Authorisiert'},
    {key: 'isdeleted', value: false, wording: 'Gelöscht'},
    {key: 'isblacklist', value: false, wording: 'Schwarze Liste'},
  ];
  public sortObject = [
    {'sortAttr': 'name', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Name'},
    {'sortAttr': 'dentists', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Ärzte'},
    {'sortAttr': 'street', 'sortDir': 'asc', 'sortType': 'number', 'wording': 'Straße'},
    {'sortAttr': 'zip', 'sortDir': 'asc', 'sortType': 'number', 'wording': 'PLZ'},
    {'sortAttr': 'city', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Stadt'},
    {'sortAttr': 'isactive', 'sortDir': 'asc', 'sortType': 'number', 'wording': 'Ist Aktiv'}
  ];

  constructor(public communication: CommunicatorService,
              public dataModel: AmbulanceviewDataModelService,
              public activatedRoute: ActivatedRoute,
              public reqTime: RequestTimeService,
              public filterService: FilterService
  ) {
    super(communication, activatedRoute, dataModel);
    this.reqTime.start();
    this.reqTime.status().subscribe((status) => {
      this.time = status;
    });
    this.index();
    this.filterService.filterObject.next(this.filterObject);
    this.filterService.filterStatus().subscribe((response) => {
      this.filterService.filterItems(this.dataModel.dataItems, this.dataModel.dataItemsFiltered);
    });
    this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
  }

  get time(): number {
    return this._time;
  }

  set time(value: number) {
    this._time = value;
  }

  get sortdirection(): string {
    return this._sortdirection;
  }

  set sortdirection(value: string) {
    this._sortdirection = value;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    this._search = value;
  }

  get sortitem(): string {
    return this._sortitem;
  }

  set sortitem(value: string) {
    if (this.sortdirection === 'asc') {
      this.sortdirection = 'desc';
    } else if (this.sortdirection === 'desc') {
      this.sortdirection = 'asc';
    }
    this._sortitem = value;
  }

  ngOnInit() {

  }


  public dhl() {

  }

}
