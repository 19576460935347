import {Injectable} from '@angular/core';
import {MessageService} from './message.service';
import {HttpClient} from '@angular/common/http';
import {TableRow} from '../classes/table-row';
import {DataModel} from '../data-models/data-model';
import {DeliveryTimes} from '../classes/delivery-times';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DeliveryTimesService extends DataModel<DeliveryTimes> {

  constructor() {
    super(DeliveryTimes);
  }

  private _toObject(array: TableRow[]): Array<any> {
    const arrayAny = [];
    for (let i = 0; i < array.length; i++) {
      const row = array[i];
      arrayAny.push(row.toObject());
    }
    return arrayAny;
  }

}
