import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {CommunicatorService} from '../../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {DeliveryTimesService} from '../../../services/delivery-times.service';
import {AppConstants} from '../../../../constants/app-constants';

@Component({
  selector: 'app-deliverytimes',
  templateUrl: './deliverytimes.component.html',
  styleUrls: ['./deliverytimes.component.scss']
})
export class DeliverytimesComponent extends BaseComponent implements OnInit {


  protected endpoint: string = AppConstants.DELIVERYTIMES;

  constructor(public communicationService: CommunicatorService, public activatedRoute: ActivatedRoute, public  deliveryTimesService: DeliveryTimesService) {
    super(communicationService, activatedRoute, deliveryTimesService);
    this.view('1');
  }

  ngOnInit() {
  }


}
