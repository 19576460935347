import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Businesshour} from '../../classes/businesshour';

@Component({
  selector: 'app-businesshours',
  templateUrl: './businesshours.component.html',
  styleUrls: ['./businesshours.component.scss']
})
export class BusinesshoursComponent implements OnInit, AfterViewInit {


  private _group: FormGroup;
  private _days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  private _businesshours: FormArray;

  constructor(private _formBuilder: FormBuilder) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      if (isNullOrUndefined(this.businesshours) && this.businesshours.length <= 1) {
        for (let i = 0; i < 7; i++) {
          const busi = new Businesshour();
          busi.day = i + 1;
          const busi2 = new Businesshour();
          busi2.day = i + 1;

          this.businesshours.push(this._formBuilder.group(busi));
          this.businesshours.push(this._formBuilder.group(busi2));
        }
      }
    });
  }

  @Input()
  get group(): FormGroup {
    return this._group;
  }

  set group(value: FormGroup) {
    this._group = value;
  }

  get days(): string[] {
    return this._days;
  }

  get businesshours(): FormArray {
    return this.group.get('businesshours') as FormArray;
  }

  deleteItem(index) {
    this.businesshours.removeAt(index);
  }


  set businesshours(value: FormArray) {
    this._businesshours = value;
  }

}
