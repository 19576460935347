import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Message} from '../classes/message';

@Injectable()
export class MessageService {

  private _messages = new BehaviorSubject<Message[]>([]);

  constructor() {
  }

  public removeMessage(message: Message) {
    const index = this._messages.getValue().indexOf(message);
    if (index > -1) {
      this._messages.getValue().splice(index, 1);
      this._messages.next(this._messages.getValue());
    }
  }

  public addMessage(message: Message, automatic: boolean = false) {
    this._messages.value.push(message);
    this._messages.next(this._messages.getValue());
    // if (automatic) {
    this._automaticDelete(message);
    // }
  }

  private _automaticDelete(message: Message) {
    setTimeout(() => {
      this.removeMessage(message);
    }, 5000);
  }

  public status(): Observable<Message[]> {
    return this._messages.asObservable();
  }
}
