import {TableRow} from './table-row';
import {FormGroup} from '@angular/forms';

export class Ambulance extends TableRow {
  private _name: string;
  private _fax: string;
  private _phone: string;
  private _note: string;
  private _email: string;
  private _dentnetstatus: string;

  private _address: any;
  private _agreement: number;
  private _articles: any = [];
  private _businesshours: any = [];
  private _collections: any = [];
  private _contacts: any = [];
  private _contracts: any = [];

  private _creditworthiness: any;
  private _creditworthiness_id;
  private _customertype: number;
  private _deliveryemail: string;
  private _deliverytpye: number;
  private _dentists: any = [];

  private _dentnetnumber: string;
  private _imexnumber: string;
  private _elabornumber: string;
  private _kzvnumber: string;

  private _disabled: string;
  private _factoring: any;

  private _internalnote: any;
  private _isdhlallowed: boolean;
  private _isonlinecollector: number;
  private _ize: string;
  private _keyacc: string;

  private _marketings: any = [];
  private _notes: any = [];
  private _onlinecollectoremail: string;
  private _parkingplaces: number;
  private _patients: any = [];

  private _paymentcondition: number;

  private _performanceletteremail: string;
  private _performanceletterstatus: number;
  private _ratingfinancial: string;
  private _ratingtechnical: string;
  private _refid: number;

  private _sales: any = [];
  private _setting: any = {};
  private _source: number;

  private _spokenlanguages: string;
  private _team: any = {};

  get note(): string {
    return this._note;
  }

  set note(value: string) {
    this._note = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get fax(): string {
    return this._fax;
  }

  set fax(value: string) {
    this._fax = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get dentnetstatus(): string {
    return this._dentnetstatus;
  }

  set dentnetstatus(value: string) {
    this._dentnetstatus = value;
  }
}
