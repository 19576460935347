import {TableRow} from './table-row';

export class Businesshour extends TableRow {
  private _day: number;
  private _opentime: string;
  private _closetime: string;
  private _surgery_id: number;

  get surgery_id(): number {
    return this._surgery_id;
  }

  set surgery_id(value: number) {
    this._surgery_id = value;
  }
  get closetime(): string {
    return this._closetime;
  }

  set closetime(value: string) {
    this._closetime = value;
  }
  get opentime(): string {
    // console.log("GET OPENTIME")
    return this._opentime;
  }

  set opentime(value: string) {
    this._opentime = value;
    // console.log("SETTING OPENING TIME")
  }
  get day(): number {
    return this._day;
  }

  set day(value: number) {
    this._day = value;
  }
}
