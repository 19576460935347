import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {Healthinsurance} from '../classes/healthinsurance';

@Injectable()
export class HealthinsuranceDataModelService extends DataModel<Healthinsurance> {

  constructor() {
    super(Healthinsurance);
    // this.buildFormGroup(new Healthinsurance());
  }

}
