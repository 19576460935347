import {TableRow} from './table-row';

export class Ambulanceview extends TableRow {

  private _name: string;
  private _dentists: string;
  private _phone: string;
  private _street: string;
  private _zip: string;
  private _city: string;
  private _isactive: number;
  private _isdeleted: number;
  private _isauthorized: number;
  private _isblacklist: number;
  private _iszipsalesactive: number;
  private _imexnumber: string;
  private _dentnetnumber: string;
  private _dentnetstatus: number;
  private _isdentnetpro: boolean;

  get isdentnetpro(): boolean {
    return this._isdentnetpro;
  }

  set isdentnetpro(value: boolean) {
    this._isdentnetpro = value;
  }

  get dentnetstatus(): number {
    return this._dentnetstatus;
  }

  set dentnetstatus(value: number) {
    this._dentnetstatus = value;
  }

  get dentnetnumber(): string {
    return this._dentnetnumber;
  }

  set dentnetnumber(value: string) {
    this._dentnetnumber = value;
  }

  get imexnumber(): string {
    return this._imexnumber;
  }

  set imexnumber(value: string) {
    this._imexnumber = value;
  }

  get iszipsalesactive(): number {
    return this._iszipsalesactive;
  }

  set iszipsalesactive(value: number) {
    this._iszipsalesactive = value;
  }

  get isblacklist(): number {
    return this._isblacklist;
  }

  set isblacklist(value: number) {
    this._isblacklist = value;
  }

  get isauthorized(): number {
    return this._isauthorized;
  }

  set isauthorized(value: number) {
    this._isauthorized = value;
  }

  get isdeleted(): number {
    return this._isdeleted;
  }

  set isdeleted(value: number) {
    this._isdeleted = value;
  }

  get isactive(): number {
    return this._isactive;
  }

  set isactive(value: number) {
    this._isactive = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get zip(): string {
    return this._zip;
  }

  set zip(value: string) {
    this._zip = value;
  }

  get street(): string {
    return this._street;
  }

  set street(value: string) {
    this._street = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get dentists(): string {
    return this._dentists;
  }

  set dentists(value: string) {
    this._dentists = value;
  }
}
