import {TableRow} from './table-row';

export class SiteMeta extends TableRow {

  private _slug: string;
  private _title: string;
  private _metadescription: string;
  private _metakeywords: string;

  get metakeywords(): string {
    return this._metakeywords;
  }

  set metakeywords(value: string) {
    this._metakeywords = value;
  }

  get metadescription(): string {
    return this._metadescription;
  }

  set metadescription(value: string) {
    this._metadescription = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get slug(): string {
    return this._slug;
  }

  set slug(value: string) {
    this._slug = value;
  }
}
