import {Component, OnInit} from '@angular/core';
import {AppConstants} from '../../../../constants/app-constants';
import {BaseComponent} from '../../../classes/base-component';
import {CommunicatorService} from '../../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {DeliveryTimesService} from '../../../services/delivery-times.service';

@Component({
  selector: 'app-delivery-times-calculator-only',
  templateUrl: './delivery-times-calculator-only.component.html',
  styleUrls: ['./delivery-times-calculator-only.component.scss']
})
export class DeliveryTimesCalculatorOnlyComponent extends BaseComponent implements OnInit {

  protected endpoint: string = AppConstants.DELIVERYTIMES;

  constructor(public communicationService: CommunicatorService, public activatedRoute: ActivatedRoute, public  deliveryTimesService: DeliveryTimesService) {
    super(communicationService, activatedRoute, deliveryTimesService);
    this.view('1');
  }

  ngOnInit() {
  }


}
