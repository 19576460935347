import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {CommunicatorService} from '../../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {SiteMetaDataModelService} from '../../../data-models/site-meta-data-model.service';

@Component({
  selector: 'app-site-meta-add',
  templateUrl: './site-meta-add.component.html',
  styleUrls: ['./site-meta-add.component.scss']
})
export class SiteMetaAddComponent extends BaseComponent implements OnInit {

  constructor(public communicationService: CommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: SiteMetaDataModelService) {
    super(communicationService, activatedRoute, dataModel);

  }

  ngOnInit() {
  }

}
