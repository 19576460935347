import {Injectable} from '@angular/core';
import {AbstractHideShow} from '../classes/abstract-hide-show';

@Injectable()
export class AuthService extends AbstractHideShow {

  constructor() {
    super();
  }

}
