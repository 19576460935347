
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {MessageService} from '../services/message.service';
import {Message} from '../classes/message';
import {AppConstants} from '../../constants/app-constants';
import {TransparentSpinnerService} from '../services/transparent-spinner.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {


  constructor(public messageService: MessageService, public transparentSpinnerService: TransparentSpinnerService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.search(AppConstants.SERVICE_DENTNET) > -1 || request.url.search(AppConstants.METAS) > -1) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + AppConstants.JWT_TOKEN_DENTNET
        }
      });

    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ` + localStorage.getItem('token')
        }
      });
    }
    return next.handle(request).catch(err => {
      const mes = new Message();
      mes.text = 'Ein Fehler ist aufgetreten';
      mes.type = 'warning';

      switch (err.code) {
        case 401:
          mes.text = 'Sie sind nicht autorisiert';
          break;
        case 404:
          mes.text = 'Angeforderte Ressource existiert nicht';
          break;
        case 500:
          mes.text = 'Ein Serverfehler ist aufgetreten';
          break;
      }

      this.messageService.addMessage(mes);
      this.transparentSpinnerService.hide();
      return observableThrowError(err);
    });
  }


}
