import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaginationComponent} from "../pagination/pagination.component";

declare var google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit, AfterViewInit {


  @ViewChild('map', {static: false}) map: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  public initMap() {
    const uluru = {lat: -25.363, lng: 131.044};

    const map = new google.maps.Map(this.map.nativeElement, {
      zoom: 4,
      center: uluru
    });
    const marker = new google.maps.Marker({
      position: uluru,
      map: map
    });
  }
}
