import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-group-image',
  templateUrl: './form-group-image.component.html',
  styleUrls: ['./form-group-image.component.scss']
})
export class FormGroupImageComponent implements OnInit {

  @Input() public group: FormGroup;
  private _type: string;

  constructor() {
  }

  ngOnInit() {
    this.group.get('image').get('type').setValue(this.type);
  }

  public addImage($event) {
    this.group.get('image').patchValue({
      file: $event,
      surgery_id: this.group.get('id').value
    });
  }

  public setFormValue($event) {
    this.group.get('image').patchValue({
      filename: $event
    });
  }

  public setImage(uri: string) {
    return uri;
  }

  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }


}
