import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AmbulancesComponent} from './sites/ambulances/ambulances.component';
import {PatientsComponent} from './sites/patients/patients.component';
import {UsersComponent} from './sites/users/users.component';
import {DentistsComponent} from './sites/dentists/dentists.component';
import {AmbulanceEditComponent} from './sites/ambulances/ambulance-edit/ambulance-edit.component';
import {AmbulanceSearchComponent} from './sites/ambulances/ambulance-search/ambulance-search.component';
import {RoutingModule} from '../routing/routing.module';
import {AmbulanceAddComponent} from './sites/ambulances/ambulance-add/ambulance-add.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PatientSearchComponent} from './sites/patients/patient-search/patient-search.component';
import {HealthinsurancesComponent} from './sites/healthinsurances/healthinsurances.component';
import {HealthinsuranceSearchComponent} from './sites/healthinsurances/healthinsurance-search/healthinsurance-search.component';
import {AmbulanceViewComponent} from './sites/ambulances/ambulance-view/ambulance-view.component';
import {AmbulanceDataModelService} from './data-models/ambulance-data-model.service';
import {LoginComponent} from './sites/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {HttpModule} from '@angular/http';
import {AmbulanceviewDataModelService} from './data-models/ambulancesview-data-model.service';
import {DashboardComponent} from './sites/dashboard/dashboard.component';
import {CommunicatorService} from './services/communicator.service';
import {SpinnerService} from './services/spinner.service';
import {AuthGuard} from './guards/auth.guard';
import {AuthService} from './services/auth.service';
import {StatusPipe} from './pipes/status.pipe';
import {FilterList} from './classes/filter-list';
import {SearchPipe} from './pipes/search.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {RequestTimeService} from './services/request-time.service';
import {MapsComponent} from './ui/maps/maps.component';
import {NotesComponent} from './ui/notes/notes.component';
import {NotesIndexComponent} from './ui/notes/notes-index/notes-index.component';
import {NotesViewComponent} from './ui/notes/notes-view/notes-view.component';
import {DentnetComponent} from './sites/dentnet/dentnet.component';
import {ImagecropperModule} from '../modules/imagecropper/imagecropper.module';
import {DentnetEditComponent} from './sites/dentnet/dentnet-edit/dentnet-edit.component';
import {DentnetCommunicatorService} from './services/dentnet-communicator.service';
import {SurgeryDataModelService} from './data-models/surgery-data-model.service';
import {ImgaddComponent} from './ui/imgadd/imgadd.component';
import {MessagesComponent} from './ui/messages/messages.component';
import {MessageService} from './services/message.service';
import {AddDentnetproComponent} from './ui/add-dentnetpro/add-dentnetpro.component';
import {FilterByTypePipe} from './pipes/filter-by-type.pipe';
import {OrderingPipe} from './pipes/ordering.pipe';
import {DentnetItemsComponent} from './sites/dentnet/dentnet-edit/dentnet-items/dentnet-items.component';
import {AmbulanceItemsComponent} from './sites/ambulances/ambulance-edit/ambulance-items/ambulance-items.component';
import {SettingsComponent} from './sites/settings/settings.component';
import {DeliverytimesComponent} from './sites/settings/deliverytimes/deliverytimes.component';
import {DeliveryTimesCalculatorComponent} from './sites/settings/delivery-times-calculator/delivery-times-calculator.component';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateDeParserFormatterService} from './services/ngb-date-de-parser-formatter.service';
import {CustomDatepickerI18n, I18n} from './services/custom-datepicker-i18n.service';
import {DeliveryTimesConfiguratorComponent} from './sites/settings/delivery-times-configurator/delivery-times-configurator.component';
import {DeliveryTimesService} from './services/delivery-times.service';
import {DeliveryTimesCalculatorOnlyComponent} from './sites/settings/delivery-times-calculator-only/delivery-times-calculator-only.component';
import {PaginationComponent} from './ui/pagination/pagination.component';
import {DentnetIndexResolverService} from './resolvers/dentnet-index-resolver.service';
import {SortByDatePipe} from './pipes/sort-by-date.pipe';
import {FilterComponent} from './ui/filter/filter.component';
import {FilterService} from './services/filter.service';
import {SiteMetasComponent} from './sites/site-metas/site-metas.component';
import {SiteMetaEditComponent} from './sites/site-metas/site-meta-edit/site-meta-edit.component';
import {HealthinsuranceDataModelService} from './data-models/healthinsurance-data-model.service';
import {SiteMetaDataModelService} from './data-models/site-meta-data-model.service';
import {InsurancesComponent} from './sites/insurances/insurances.component';
import {InsuranceEditComponent} from './sites/insurances/insurance-edit/insurance-edit.component';
import {FormGroupImageComponent} from './ui/form-group-image/form-group-image.component';
import {UploadsComponent} from './sites/uploads/uploads.component';
import {UploadDataModelService} from './data-models/upload-data-model.service';
import {UploadEditComponent} from './sites/uploads/upload-edit/upload-edit.component';
import {InsuranceAddComponent} from './sites/insurances/insurance-add/insurance-add.component';
import {SiteMetaAddComponent} from './sites/site-metas/site-meta-add/site-meta-add.component';
import {UploadAddComponent} from './sites/uploads/upload-add/upload-add.component';
import {BusinesshoursComponent} from './ui/businesshours/businesshours.component';
import {SearchlogsComponent} from './sites/searchlogs/searchlogs.component';
import {SearchlogDataModelService} from './data-models/searchlog-data-model.service';
import {DentnetItemResolverService} from './resolvers/dentnet-item-resolver.service';
import {InsurancesItemResolverService} from './resolvers/insurances-item-resolver.service';
import {SiteMetaItemResolverService} from './resolvers/site-meta-item-resolver.service';
import {DownloadItemResolverService} from './resolvers/download-item-resolver.service';
import {TransparentSpinnerService} from './services/transparent-spinner.service';
import { KzvImportComponent } from './sites/kzv-import/kzv-import.component';
import { CsvReaderComponent } from './ui/csv-reader/csv-reader.component';
import {KzvService} from './services/kzv.service';
import { PapaParseModule } from 'ngx-papaparse';
import {KzvCsvDataModelService} from './data-models/kzv-csv-data-model.service';
import { KzvSortFieldsComponent } from './sites/kzv-import/kzv-sort-fields/kzv-sort-fields.component';
import {SortablejsModule} from 'ngx-sortablejs';
import { ImportStatisticsComponent } from './sites/kzv-import/import-statistics/import-statistics.component';

@NgModule({
  declarations: [
    AppComponent,
    AmbulancesComponent,
    PatientsComponent,
    UsersComponent,
    DentistsComponent,
    AmbulanceEditComponent,
    AmbulanceSearchComponent,
    AmbulanceAddComponent,
    PatientSearchComponent,
    HealthinsurancesComponent,
    HealthinsuranceSearchComponent,
    AmbulanceViewComponent,
    LoginComponent,
    DashboardComponent,
    StatusPipe,
    SearchPipe,
    SortPipe,
    FilterByTypePipe,
    MapsComponent,
    NotesComponent,
    NotesIndexComponent,
    NotesViewComponent,
    DentnetComponent,
    DentnetEditComponent,
    ImgaddComponent,
    MessagesComponent,
    AddDentnetproComponent,
    OrderingPipe,
    DentnetItemsComponent,
    AmbulanceItemsComponent,
    SettingsComponent,
    DeliverytimesComponent,
    DeliveryTimesCalculatorComponent,
    DeliveryTimesConfiguratorComponent,
    DeliveryTimesCalculatorOnlyComponent,
    PaginationComponent,
    SortByDatePipe,
    FilterComponent,
    SiteMetasComponent,
    SiteMetaEditComponent,
    InsurancesComponent,
    InsuranceEditComponent,
    FormGroupImageComponent,
    UploadsComponent,
    UploadEditComponent,
    InsuranceAddComponent,
    SiteMetaAddComponent,
    UploadAddComponent,
    BusinesshoursComponent,
    SearchlogsComponent,
    KzvImportComponent,
    CsvReaderComponent,
    KzvSortFieldsComponent,
    ImportStatisticsComponent
  ],
  imports: [
    SortablejsModule
      .forRoot({
        animation: 150,
        sort: true,
        disabled: false

      }),
    BrowserModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ImagecropperModule,
    NgbModule.forRoot(),
    PapaParseModule
  ],
  providers: [
    I18n,
    SortByDatePipe,
    FormBuilder,
    DeliveryTimesService,
    DentnetIndexResolverService,
    DownloadItemResolverService,
    DentnetItemResolverService,
    InsurancesItemResolverService,
    SiteMetaItemResolverService,
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateDeParserFormatterService
    },
    AmbulanceviewDataModelService,
    AmbulanceDataModelService,
    TransparentSpinnerService,
    HealthinsuranceDataModelService,
    SearchlogDataModelService,
    UploadDataModelService,
    SiteMetaDataModelService,
    CommunicatorService,
    SurgeryDataModelService,
    DentnetCommunicatorService,
    SpinnerService,
    AuthService,
    KzvService,
    AuthGuard,
    FilterList,
    SearchPipe,
    SortPipe,
    MessageService,
    FilterService,
    RequestTimeService,
    FilterByTypePipe,
    OrderingPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    KzvCsvDataModelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
