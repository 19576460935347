import {TableRow} from './table-row';

export class Image extends TableRow {
  private _alt: string;
  private _filename: string;
  private _ordering: number;
  private _path: string;
  private _surgery_id: string;
  private _title: string;
  private _type: string;
  private _file: string;
  private _uri: string;

  get uri(): string {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value;
  }
  get type(): string {
    return this._type;
  }

  get file(): string {
    return this._file;
  }

  set file(value: string) {
    this._file = value;
  }

  set type(value: string) {
    this._type = value;
  }
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }
  get surgery_id(): string {
    return this._surgery_id;
  }

  set surgery_id(value: string) {
    this._surgery_id = value;
  }
  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }
  get ordering(): number {
    return this._ordering;
  }

  set ordering(value: number) {
    this._ordering = value;
  }
  get filename(): string {
    return this._filename;
  }

  set filename(value: string) {
    this._filename = value;
  }
  get alt(): string {
    return this._alt;
  }

  set alt(value: string) {
    this._alt = value;
  }
}
