import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public $http: HttpClient, public auth: AuthService, public router: Router) {
  }

  ngOnInit() {
  }

  public login(f: NgForm) {
    const values = f.value;

    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    const req = this.$http.post(environment.myservice + 'users/token', {
      username: values.username,
      password: values.password
    }, {
      headers: headers
    })
      .subscribe((response: any) => {

        if (response.success) {
          localStorage.setItem('token', response.data.token);
          this.auth.show();
          this.router.navigate(['/dashboard']);
        }
        req.unsubscribe();

      });
  }


}
