import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, keys: string, term: string): any {
    if (!term) {
      return value;
    }
    const keysArray = keys.split(',');

    return (value || [])
      .filter((item, i) => {
        for (let n = 0; n < keysArray.length; n++) {
          const key = keysArray[n];
          const tester = new RegExp('(^' + term + ')', 'gi');
          if (tester.test(item[key])) {
            return item;
          }
        }
      });
  }

}
