import {DataModel} from '../data-models/data-model';
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {CommunicatorService} from '../services/communicator.service';
import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';

export class BaseComponent implements OnDestroy {

  protected dataModel: DataModel<any>;
  protected endpoint: string;
  protected _subscriptions: Subscription[] = [];

  constructor(public communication: CommunicatorService,
              public activatedRoute: ActivatedRoute, public dataModelD: DataModel<any>) {
    this.dataModel = this.dataModelD;
  }

  public index() {
    this.communication.spinner.show();
    this.communication.index(this.endpoint, this.dataModel).subscribe((response) => {
      console.log(response);
    });
  }

  public view(uname?: string): any {
    this.communication.spinner.show();
    if (isNullOrUndefined(uname)) {
      uname = this.activatedRoute.snapshot.params['uname'];
    }
    const sub = this.communication.view(this.endpoint, this.dataModel, uname).subscribe((response) => {
      // console.log(this.dataModel.data.value);
    });
    this.subscriptions.push(sub);
  }

  public edit(f: FormGroup, isReactive: boolean = false, uname?: string) {

    if (!!f && !!f.disable) {
      f.disable();
    }
    if (isNullOrUndefined(uname)) {
      uname = this.activatedRoute.snapshot.params['uname'];
    }

    /*
    if (isReactive) {
      this.dataModel.data.value.init(f.value);
    }*/

    const sub = this.communication.edit(this.endpoint, this.dataModel, uname, f).subscribe((response: any) => {

      // Todo auslagern
      if (!isNullOrUndefined(response.success)) {
        console.log(response.success);
      } else {
        for (const key in response) {
          if (Object(response).hasOwnProperty(key)) {
            this.dataModel.formGroup.get(key).setErrors({required: true});
          }
        }
      }
      if (!!f && !!f.enable) {
        f.enable();
      }
    });
    this.subscriptions.push(sub);

  }

  public add(f: FormGroup) {
    // f.controls.name.setErrors({required: true})
    const sub = this.communication.add(this.endpoint, f, this.activatedRoute.snapshot).subscribe((response) => {
      // if (confirm('Die Daten werden zurückgesetzt')) {
        this.dataModel.formGroup.reset();
      // }
    });
    this.subscriptions.push(sub);
  }

  public delete(id) {
    if (confirm('Sind Sie sich wirklich sicher?')) {
      const sub = this.communication.delete(this.endpoint, id, this.dataModel).subscribe((response) => {
        console.log('---------------');
        console.log(response);
        console.log('---------------');

        if (!isNullOrUndefined(this.dataModel) && !isNullOrUndefined(this.dataModel.dataItems)) {
          for (let i = 0; i < this.dataModel.dataItems.value.length; i++) {
            if (this.dataModel.dataItems.value[i].id === id) {
              this.dataModel.dataItems.value.splice(i, 1);
              this.dataModel.dataItemsFiltered.next(this.dataModel.dataItems.value);
            }
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }


  ngOnDestroy() {
    console.log(this.dataModel);
    for (let i = 0; i < this._subscriptions.length; i++) {
      this._subscriptions[i].unsubscribe();
    }
  }

  get subscriptions(): Subscription[] {
    return this._subscriptions;
  }

  set subscriptions(value: Subscription[]) {
    this._subscriptions = value;
  }
}
