import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DentnetCommunicatorService} from '../services/dentnet-communicator.service';
import {HealthinsuranceDataModelService} from '../data-models/healthinsurance-data-model.service';
import {RequestTimeService} from '../services/request-time.service';
import {AppConstants} from '../../constants/app-constants';

@Injectable()
export class InsurancesItemResolverService implements Resolve<any> {

  constructor(private communicator: DentnetCommunicatorService, private dataModel: HealthinsuranceDataModelService, public requestTimeService: RequestTimeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const uname = route.params['uname'];
    if (this.requestTimeService.calcDiff(this.dataModel.maxSecondsBetween, this.dataModel.lastRequest)
    ) {
      this.dataModel.lastRequest = Date.now();
      return this.communicator.view(AppConstants.INSURANCES, this.dataModel, uname);
    }
    return true;
  }

}
