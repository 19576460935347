import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {CommunicatorService} from '../../../services/communicator.service';
import {AmbulanceDataModelService} from '../../../data-models/ambulance-data-model.service';
import {AppConstants} from '../../../../constants/app-constants';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-ambulance-add',
  templateUrl: './ambulance-add.component.html',
  styleUrls: ['./ambulance-add.component.scss']
})
export class AmbulanceAddComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.AMBULANCES;

  constructor(
    public communication: CommunicatorService,
    public dataModel: AmbulanceDataModelService,
    public activatedRoute: ActivatedRoute
  ) {
    super(communication, activatedRoute, dataModel);

  }


  ngOnInit() {
    /*
    this.form = new FormGroup({
      'name': new FormControl(this.dataModel.data.value.name, [
        Validators.required, Validators.minLength(4)
      ])
    });
    */
  }


}
