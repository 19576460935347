export class SortObject {

  private _sortAttr: string;
  private _sortDir: string;
  private _sortType: any;

  get sortDir(): string {
    return this._sortDir;
  }

  set sortDir(value: string) {
    this._sortDir = value;
  }

  get sortAttr(): string {
    return this._sortAttr;
  }

  set sortAttr(value: string) {
    this._sortAttr = value;
  }

  get sortType(): any {
    return this._sortType;
  }

  set sortType(value: any) {
    this._sortType = value;
  }

}
