import { isArray } from 'rxjs/util/isArray';
import { isObject } from 'rxjs/util/isObject';
import { isNullOrUndefined } from 'util';

export class TableRow {

  private _id: number;
  private _created: Date;
  private _modified: Date;

  get id (): number {
    return this._id;
  }

  constructor () {

  }

  set id ( value: number ) {
    this._id = value;
  }

  public toObject ( object: Object = this ): any {
    const tmpObj = {};

    for ( const key in object ) {
      if ( key.charAt ( 0 ) !== '_' && typeof object[ key ] !== 'function' ) {
        if ( isObject ( object[ key ] ) && ! isArray ( object[ key ] ) ) {
          if ( object[ key ] instanceof TableRow ) {
            tmpObj[ key ] = object[ key ].toObject ();
          } else {
            tmpObj[ key ] = object[ key ];
          }
        } else if ( isArray ( object[ key ] ) ) {
          tmpObj[ key ] = [];
          for ( let i = 0; i < object[ key ].length; i ++ ) {
            tmpObj[ key ][ i ] = object[ key ][ i ].toObject ();
          }
        } else {
          tmpObj[ key ] = object[ key ];
        }
      }
    }
    return tmpObj;
  }

  public init ( obj: object, tmpObj: TableRow = this ) {

    for ( const key in obj ) {
      if ( obj.hasOwnProperty ( key ) ) {
        if ( obj[ key ] === null ) {
          if ( tmpObj[ key ] instanceof TableRow ) {
            tmpObj[ key ] = tmpObj[ key ];
          }
        } else if ( isObject ( obj[ key ] ) && ! isArray ( obj[ key ] ) ) {
          if ( ! isNullOrUndefined ( tmpObj[ key ] ) ) {
            if ( tmpObj[ key ] instanceof TableRow ) {
              tmpObj[ key ].init ( obj[ key ] );
            } else {
              tmpObj[ key ] = obj[ key ];
            }
          }
        } else if ( isArray ( obj[ key ] ) ) {
          if ( ! isNullOrUndefined ( tmpObj[ key ] ) && tmpObj[ key ].length !== 0 && ! isNullOrUndefined ( tmpObj[ key ][ 0 ].constructor ) ) {
            const copy: any = Object.assign ( new tmpObj[ key ][ 0 ].constructor (), {} );

            tmpObj[ key ] = [];
            for ( let i = 0; i < obj[ key ].length; i ++ ) {
              if ( copy.emptyObj ) {
                copy.emptyObj ();
              }
              if ( copy.constructor ) {
                tmpObj[ key ][ i ] = Object.assign ( new copy.constructor (), copy.init ( obj[ key ][ i ] ) );
              }
            }
          } else {
            console.log ( tmpObj[ key ] );
            tmpObj[ key ] = [];
          }

        } else {
          tmpObj[ key ] = obj[ key ];
        }
      }
    }

    return tmpObj;
  }

  public emptyObj ( tmpObj = this ) {
    for ( const key in tmpObj ) {
      if ( isArray ( tmpObj[ key ] ) ) {
        (<any>tmpObj)[ key ] = [];
      } else {
        tmpObj[ key ] = undefined;
      }
    }
  }

  get modified (): Date {
    return this._modified;
  }

  set modified ( value: Date ) {
    this._modified = value;
  }

  get created (): Date {
    return this._created;
  }

  set created ( value: Date ) {
    this._created = value;
  }
}
