import {TableRow} from './table-row';

export class Searchlog extends TableRow {
  private _searchtype: string;
  private _searchterm: string;
  private _termcount: string;

  get termcount(): string {
    return this._termcount;
  }

  set termcount(value: string) {
    this._termcount = value;
  }

  get searchterm(): string {
    return this._searchterm;
  }

  set searchterm(value: string) {
    this._searchterm = value;
  }

  get searchtype(): string {
    return this._searchtype;
  }

  set searchtype(value: string) {
    this._searchtype = value;
  }
}
