import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {Searchlog} from '../classes/searchlog';

@Injectable()
export class SearchlogDataModelService extends DataModel<Searchlog> {

  constructor() {
    super(Searchlog);
  }

}
