import {Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef} from '@angular/core';
import {IImportStatistics} from './import-statistics';
import {KzvService} from '../../../services/kzv.service';

@Component({
  selector: 'app-import-statistics',
  templateUrl: './import-statistics.component.html',
  styleUrls: ['./import-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportStatisticsComponent implements OnInit {

  get statistics(): IImportStatistics {
    return this._statistics;
  }

  set statistics(value: IImportStatistics) {
    this._statistics = value;
    this.change.markForCheck();
    this.change.detectChanges();
  }

  private _statistics: IImportStatistics;

  constructor(public KzvService: KzvService, private change: ChangeDetectorRef) {
    this._statistics = this.KzvService.importStatistics;
  }

  ngOnInit() {
  }


}
