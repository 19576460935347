import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Image} from '../classes/image';
import {FormControl} from '@angular/forms';

;

@Pipe({
  name: 'filterByType',
  pure: false
})
export class FilterByTypePipe implements PipeTransform {

  transform(arrayItems: Array<FormControl>, arg: string): any {

    if (isNullOrUndefined(arrayItems)) {
      return [];
    }
    const items = arrayItems.filter(item => {
      const img: Image = item.value;
      if (img.type === arg) {
        return item;
      }
    });
    return items;
  }

}
