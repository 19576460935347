import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../services/message.service';
import {Message} from '../../classes/message';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  private _messages: Message[] = [];
  private _sub: Subscription;

  constructor(public messageService: MessageService) {
    this._sub = messageService.status().subscribe(state => {
      this.messages = state;
    });


  }

  ngOnDestroy(): void {
    if (!isNullOrUndefined(this._sub)) {
      this._sub.unsubscribe();
    }
  }

  ngOnInit() {
  }

  get messages(): Message[] {
    return this._messages;
  }

  set messages(value: Message[]) {
    this._messages = value;
  }

}
