import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(value: Array<any>): any {
    return value.sort((a: any, b: any) => {
      return +new Date(a.date) - +new Date(b.date);
    });
  }

}
