import {Component, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Holiyday} from '../../../classes/holiyday';
import {isNullOrUndefined} from 'util';
import {WorkingType} from '../../../classes/working-type';
import {DeliveryTimesService} from '../../../services/delivery-times.service';

@Component({
  selector: 'app-delivery-times-configurator',
  templateUrl: './delivery-times-configurator.component.html',
  styleUrls: ['./delivery-times-configurator.component.scss']
})
export class DeliveryTimesConfiguratorComponent implements OnInit {


  private _dateVarA: NgbDateStruct;
  private _dateVarB: NgbDateStruct;
  private _dateA: Holiyday = new Holiyday();
  private _dateB: Holiyday = new Holiyday();
  private _workingAdd: WorkingType = new WorkingType();


  constructor(public  deliveryTimesService: DeliveryTimesService) {

  }

  ngOnInit() {
  }

  public addDate(date: NgbDateStruct, holiDay: Holiyday, dates: Holiyday[], $event): void {
    if (isNullOrUndefined(date)) {
      return;
    }
    const keyCode = $event.which || $event.keyCode;


    if (keyCode === 13 || $event.type === 'click') {
      date = this.formatDate(date);
      if (isNaN(date.day) || isNaN(date.month) || isNaN(date.year)) {
        return;
      }

      const holi = new Holiyday();
      holi.date = new Date(date.year, date.month - 1, date.day);
      holi.daysToAdd = holiDay.daysToAdd;
      dates.unshift(holi.toObject());
      dates.sort((a: any, b: any) => {
        return +new Date(a.date) - +new Date(b.date);
      });

      date.year = null;
      date.month = null;
      date.day = null;
      holiDay.daysToAdd = null;
    }
  }

  public formatDate(date: string | NgbDateStruct): NgbDateStruct {
    if (typeof date === 'string') {
      const dateArray = date.split('.');
      return {
        day: parseInt(dateArray[0], 10),
        month: parseInt(dateArray[1], 10),
        year: parseInt(dateArray[2], 10)
      };
    }
    return date;
  }

  public removeDate(index: number, dates: Date[]) {
    dates.splice(index, 1);
  }


  public addWorkingType() {
    if (isNullOrUndefined(this.workingAdd.name) || this.workingAdd.name === '') {
      return;
    }

    const work = {
      name: '',
      days: [],
      isChina: false
    };

    work.name = this.workingAdd.name;
    work.days[0] = this.workingAdd.days[0];
    work.days[1] = this.workingAdd.days[1];
    work.days[2] = this.workingAdd.days[2];
    work.days[3] = this.workingAdd.days[3];
    work.days[4] = this.workingAdd.days[4];
    work.isChina = this.workingAdd.isChina;

    this.deliveryTimesService.data.value.payload.deliveryTypes.unshift(work);

    this.workingAdd.emptyObj();
  }


  get dateB(): Holiyday {
    return this._dateB;
  }

  set dateB(value: Holiyday) {
    this._dateB = value;
  }

  get dateA(): Holiyday {
    return this._dateA;
  }

  set dateA(value: Holiyday) {
    this._dateA = value;
  }

  get dateVarB(): NgbDateStruct {
    return this._dateVarB;
  }

  set dateVarB(value: NgbDateStruct) {
    this._dateVarB = value;
  }

  get dateVarA(): NgbDateStruct {
    return this._dateVarA;
  }

  set dateVarA(value: NgbDateStruct) {
    this._dateVarA = value;
  }

  get workingAdd(): WorkingType {
    return this._workingAdd;
  }

  set workingAdd(value: WorkingType) {
    this._workingAdd = value;
  }

  public stopProp($event) {
    $event.stopPropagation();
  }

  public toGermanDate(date: NgbDateStruct): string {
    if (isNullOrUndefined(date) || isNullOrUndefined(date.day) || isNullOrUndefined(date.month) || isNullOrUndefined(date.year)) {
      return '';
    }
    return ('0' + date.day).slice(-2) + '.' + ('0' + date.month).slice(-2) + '.' + date.year;
  }

  public deleteDeliveryType(worktype: WorkingType) {
    const index = this.deliveryTimesService.data.value.payload.deliveryTypes.indexOf(worktype);
    this.deliveryTimesService.data.value.payload.deliveryTypes.splice(index, 1);
  }


  public toDateString(date: Date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return this.toGermanDate({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    });
  }

}
