import { Injectable } from '@angular/core';
import {DentnetCommunicatorService} from '../services/dentnet-communicator.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {RequestTimeService} from '../services/request-time.service';
import {AppConstants} from '../../constants/app-constants';
import {SiteMetaDataModelService} from '../data-models/site-meta-data-model.service';

@Injectable()
export class SiteMetaItemResolverService implements Resolve<any> {

  constructor(private communicator: DentnetCommunicatorService, private dataModel: SiteMetaDataModelService, public requestTimeService: RequestTimeService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const uname = route.params['uname'];
    if (this.requestTimeService.calcDiff(this.dataModel.maxSecondsBetween, this.dataModel.lastRequest)
    ) {
      this.dataModel.lastRequest = Date.now();
      return this.communicator.view(AppConstants.METAS, this.dataModel, uname);
    }
    return true;
  }

}
