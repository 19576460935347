import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {AppConstants} from '../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {SurgeryDataModelService} from '../../data-models/surgery-data-model.service';
import {FilterService} from '../../services/filter.service';
import {Subscription} from 'rxjs';
import {Surgery} from '../../classes/surgery';

@Component({
  selector: 'app-dentnet',
  templateUrl: './dentnet.component.html',
  styleUrls: ['./dentnet.component.scss']
})
export class DentnetComponent extends BaseComponent implements OnInit, OnDestroy {


  protected endpoint = AppConstants.SURGERIES;

  private _fullTextSearch: string;
  private _subscriptions2: Subscription[] = [];

  public pageItems: Array<Surgery> = [];

  public sortItems = [
    {'sortAttr': '', 'sortDir': '', 'sortType': 'string', 'wording': ''},
    {'sortAttr': 'title', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Name'},
    {'sortAttr': 'street', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Straße'},
    {'sortAttr': 'zip', 'sortDir': 'asc', 'sortType': 'number', 'wording': 'PLZ'},
    {'sortAttr': 'city', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Stadt'},
    {'sortAttr': 'isactive', 'sortDir': 'asc', 'sortType': 'number', 'wording': 'Ist Aktiv'}
  ];

  public filterObject = [
    {key: 'ispro', value: false, 'wording': 'Dent-net® Pro Praxis'},
    {key: 'issleep', value: false, 'wording': 'Schlafmediziner'},
    {key: 'isimplant', value: false, 'wording': 'Implantologe'},
    {key: 'isactive', value: false, 'wording': 'Ist aktiv geschaltet'},
    {key: 'isvisible', value: false, 'wording': 'Ist Sichtbar'}
  ];

  constructor(public communication: DentnetCommunicatorService,
              public dataModel: SurgeryDataModelService,
              public activatedRoute: ActivatedRoute,
              public filterService: FilterService) {
    super(communication, activatedRoute, dataModel);

    this.filterService.filterObject.next(this.filterObject);

    const sub = this.filterService.filterStatus().subscribe((response) => {
      this.filterService.filterItems(this.dataModel.dataItems, this.dataModel.dataItemsFiltered);

    });
    this._subscriptions2.push(sub);

    const sub2 = this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
    this._subscriptions2.push(sub2);

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (let i = 0; i < this._subscriptions2.length; i++) {
      this._subscriptions2[i].unsubscribe();
    }
  }

  get fullTextSearch(): string {
    return this._fullTextSearch;
  }

  set fullTextSearch(value: string) {
    this._fullTextSearch = value;
  }


}
