import {Injectable} from '@angular/core';
import {CommunicatorService} from './communicator.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {SpinnerService} from './spinner.service';
import {RequestTimeService} from './request-time.service';
import {AppConstants} from '../../constants/app-constants';
import {MessageService} from './message.service';
import {TransparentSpinnerService} from './transparent-spinner.service';

@Injectable()
export class DentnetCommunicatorService extends CommunicatorService {

  protected _SERVICE = AppConstants.SERVICE_DENTNET;

  constructor(public $http: HttpClient, public router: Router, public spinner: SpinnerService, public transparentSpinner: TransparentSpinnerService, public requestTimeService: RequestTimeService, public messageService: MessageService) {
    super($http, router, spinner, transparentSpinner, requestTimeService, messageService);
  }

}
