import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../classes/base-component';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {ActivatedRoute} from '@angular/router';
import {HealthinsuranceDataModelService} from '../../data-models/healthinsurance-data-model.service';
import {AppConstants} from '../../../constants/app-constants';
import {Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import {Healthinsurance} from '../../classes/healthinsurance';
import {PaginationComponent} from '../../ui/pagination/pagination.component';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss']
})
export class InsurancesComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  protected endpoint = AppConstants.INSURANCES;
  private _fulltextSearch: string;
  public pageItems: Array<Healthinsurance> = [];

  private _subscrip: Subscription[] = [];
  @ViewChild(PaginationComponent, {static: false}) pagination: PaginationComponent;

  public sortObject = [
    {'sortAttr': '', 'sortDir': '', 'sortType': 'string', 'wording': ''},
    {'sortAttr': 'name', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Name'},
    {'sortAttr': 'uri', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Uri'},
  ];


  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: HealthinsuranceDataModelService, public filterService: FilterService) {
    super(communicationService, activatedRoute, dataModel);
    this.index();

    const sub2 = this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
    this._subscrip.push(sub2);

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const sub3 = this.dataModel.dataItemsFiltered.subscribe((dataChange) => {
      this.pagination.list = dataChange;
    });
    this._subscrip.push(sub3);
  }


  get fulltextSearch(): string {
    return this._fulltextSearch;
  }

  set fulltextSearch(value: string) {
    this._fulltextSearch = value;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (let i = 0; i < this._subscrip.length; i++) {
      this._subscrip[i].unsubscribe();
    }
  }

}
