import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {KzvCsvDataModelService} from '../../../data-models/kzv-csv-data-model.service';
import {IKzvFieldnames} from './kzv-fieldnames';


@Component({
  selector: 'app-kzv-sort-fields',
  templateUrl: './kzv-sort-fields.component.html',
  styleUrls: ['./kzv-sort-fields.component.scss']
})
export class KzvSortFieldsComponent implements OnInit {
  get fields(): IKzvFieldnames[] {
    return this._fields;
  }

  set fields(value: IKzvFieldnames[]) {
    this._fields = value;
  }

  private _demo: string[];
  get demo(): string[] {
    return this._demo;
  }

  set demo(value: string[]) {
    this._demo = value;
  }


  private _fields: IKzvFieldnames[] = [
    {
      'fieldname': 'kzvnumber',
      'label': 'KZV Nummer'

    },
    {
      'fieldname': 'name',
      'label': 'Praxis Name'
    },
    {
      'fieldname': 'street',
      'label': 'Straße'
    },
    {
      'fieldname': 'housenumber',
      'label': 'Hausnummer'
    },
    {
      'fieldname': 'zip',
      'label': 'PLZ'
    },
    {
      'fieldname': 'city',
      'label': 'Stadt'
    },
    {
      'fieldname': 'classification',
      'label': 'Klassifizierung'
    }
  ];


  private _complete = false;

  get complete(): boolean {
    return this._complete;
  }

  @Output() onCompleteChanged: EventEmitter<boolean> = new EventEmitter();

  set complete(value: boolean) {
    this._complete = value;
    this.onCompleteChanged.emit(value);
  }

  constructor(public kzvCsv: KzvCsvDataModelService) {
  }

  ngOnInit() {
    this.demo = this.kzvCsv.items[0];
    this.fillUpFields();
  }


  demodata() {

    for (let j = 0; j < this.kzvCsv.items.length; j++) {
      this.kzvCsv.mappedItems.push(this.mapSorting(this.kzvCsv.items[j]));
    }
    this.complete = true;
  }


  private mapSorting(item) {
    const obj = {};
    for (let i = 0; i < this._fields.length; i++) {
      obj[this._fields[i].fieldname] = item[i];
    }
    return obj;
  }


  /**
   * there are more fields in the csv, than we have to sort, so we have to fill up this.fields to make sortableJS
   * work for all demo fields.
   *
   */
  private fillUpFields() {
    const diff = this.demo.length - this.fields.length;
    for (let i = 0; i < diff; i++) {
      this.fields.push({
        'label': '',
        'fieldname': 'empty_' + i
      });
    }
  }


  public deleteField(fieldvalue) {
    this.fields.forEach(function (value, index, array) {
      if (value.fieldname === fieldvalue.fieldname) {
        array[index] = {
          'label': '',
          'fieldname': 'deleted' + Date.now()
        };
      }
    });
  }


  sorted(event){
    console.log(event)
  }

}


