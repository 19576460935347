import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SearchlogDataModelService} from '../../data-models/searchlog-data-model.service';
import {BaseComponent} from '../../classes/base-component';
import {AppConstants} from '../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import {Searchlog} from '../../classes/searchlog';

@Component({
  selector: 'app-searchlogs',
  templateUrl: './searchlogs.component.html',
  styleUrls: ['./searchlogs.component.scss']
})
export class SearchlogsComponent extends BaseComponent implements OnInit, OnDestroy {

  protected endpoint = AppConstants.SEARCHLOGS;
  private _fulltextSearch: string;
  private _subscrip: Subscription[] = [];
  public sortObject = [
    {'sortAttr': 'searchterm', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Suchterm'},
    {'sortAttr': 'termcount', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Anzahl der Anfragen'},
    {'sortAttr': 'modified', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Zuletzt angefragt am'}
  ];
  public pageItems: Array<Searchlog> = [];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: SearchlogDataModelService, public filterService: FilterService) {
    super(communicationService, activatedRoute, dataModel);
    this.index();
    const sub2 = this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
    this._subscrip.push(sub2);
  }

  ngOnInit() {
  }

  get fulltextSearch(): string {
    return this._fulltextSearch;
  }

  set fulltextSearch(value: string) {
    this._fulltextSearch = value;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (let i = 0; i < this._subscrip.length; i++) {
      this._subscrip[i].unsubscribe();
    }
  }
}
