import {Injectable} from '@angular/core';
import {Surgery} from '../classes/surgery';
import {DataModel} from './data-model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SurgeryDataModelService extends DataModel<Surgery> {

  constructor() {
    super(Surgery);
    // this.buildFormGroup(new Surgery());
  }

}
