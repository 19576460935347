import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {ActivatedRoute} from '@angular/router';
import {HealthinsuranceDataModelService} from '../../../data-models/healthinsurance-data-model.service';
import {AppConstants} from '../../../../constants/app-constants';
import {Image} from '../../../classes/image';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-insurance-edit',
  templateUrl: './insurance-edit.component.html',
  styleUrls: ['./insurance-edit.component.scss']
})
export class InsuranceEditComponent extends BaseComponent implements OnInit, OnDestroy {

  protected endpoint = AppConstants.INSURANCES;
  private _pzrList = [1, 2, 4];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: HealthinsuranceDataModelService) {
    super(communicationService, activatedRoute, dataModel);
    this.view();
  }

  ngOnInit() {
  }


  public setValue(key) {
    const control = this.dataModel.formGroup.get(key);
    if (control.value === 0) {
      control.setValue(1);
    } else {
      control.setValue(0);
    }
    console.log(control.value);
  }

  public checkValue(key) {
    const control = this.dataModel.formGroup.get(key);
    return control.value === 0 || !control.value;
  }

  get pzrList(): number[] {
    return this._pzrList;
  }

  set pzrList(value: number[]) {
    this._pzrList = value;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dataModel.formGroup.reset();
  }

}
