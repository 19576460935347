import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {Upload} from '../classes/upload';

@Injectable()
export class UploadDataModelService extends DataModel<Upload> {

  constructor() {
    super(Upload);
    // this.buildFormGroup(new Upload());
  }

}
