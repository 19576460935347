import {Component, OnInit} from '@angular/core';
import {ItemsComponent} from '../../../../classes/items-component';
import {Ambulanceview} from '../../../../classes/ambulanceview';
import {Ambulance} from '../../../../classes/ambulance';

@Component({
  selector: 'app-ambulance-items',
  templateUrl: './ambulance-items.component.html',
  styleUrls: ['./ambulance-items.component.scss']
})
export class AmbulanceItemsComponent extends ItemsComponent<Ambulance> implements OnInit {

  constructor() {
    super();
    this.data = new Ambulance();
  }

  ngOnInit() {
  }

}
