import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {AppConstants} from '../../../../constants/app-constants';
import {ActivatedRoute} from '@angular/router';
import {AmbulanceDataModelService} from '../../../data-models/ambulance-data-model.service';
import {CommunicatorService} from '../../../services/communicator.service';

@Component({
  selector: 'app-ambulance-edit',
  templateUrl: './ambulance-edit.component.html',
  styleUrls: ['./ambulance-edit.component.scss']
})
export class AmbulanceEditComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.AMBULANCES;

  constructor(
    public communication: CommunicatorService,
    public dataModel: AmbulanceDataModelService,
    public activatedRoute: ActivatedRoute
  ) {
    super(communication, activatedRoute, dataModel);
    this.view();
  }

  ngOnInit() {
  }

}
