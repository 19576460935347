import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {ActivatedRoute} from '@angular/router';
import {UploadDataModelService} from '../../../data-models/upload-data-model.service';
import {AppConstants} from '../../../../constants/app-constants';

@Component({
  selector: 'app-upload-edit',
  templateUrl: './upload-edit.component.html',
  styleUrls: ['./upload-edit.component.scss']
})
export class UploadEditComponent extends BaseComponent implements OnInit {


  protected endpoint = AppConstants.UPLOADS;
  private _types = [0, 1, 2, 3];
  private _typesWording = ['--Auswählen--', 'Presse', 'Download Patienten', 'Download Zahnarzt'];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: UploadDataModelService) {
    super(communicationService, activatedRoute, dataModel);
    this.view();
  }

  ngOnInit() {
  }

  get typesWording(): (string | string | string)[] {
    return this._typesWording;
  }

  set typesWording(value: (string | string | string)[]) {
    this._typesWording = value;
  }

  get types(): number[] {
    return this._types;
  }

  set types(value: number[]) {
    this._types = value;
  }

  public setDownload() {
    return this.dataModel.data.value.uri;
  }

}
