import {TableRow} from './table-row';

export class Holiyday extends TableRow {

  private _date: Date;
  private _daysToAdd: number;

  get daysToAdd(): number {
    return this._daysToAdd;
  }

  set daysToAdd(value: number) {
    this._daysToAdd = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }
}
