import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {UploadDataModelService} from '../../data-models/upload-data-model.service';
import {AppConstants} from '../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import {Upload} from '../../classes/upload';
import {PaginationComponent} from '../../ui/pagination/pagination.component';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  protected endpoint = AppConstants.UPLOADS;
  private _subscrip: Subscription[] = [];
  @ViewChild(PaginationComponent, {static: false}) pagination: PaginationComponent;
  public sortObject = [
    {'sortAttr': 'ordering', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Sortierung'},
    {'sortAttr': 'filename', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Datei-Name'},
    {'sortAttr': 'itemname', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Name'},
    {'sortAttr': 'type', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Art'},
    {'sortAttr': 'created', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Erstellt'}
  ];
  public pageItems: Array<Upload> = [];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: UploadDataModelService, public filterService: FilterService) {
    super(communicationService, activatedRoute, dataModel);
    this.index();
    const sub2 = this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
    this._subscrip.push(sub2);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const sub3 = this.dataModel.dataItemsFiltered.subscribe((dataChange) => {
      this.pagination.list = dataChange;
    });
    this._subscrip.push(sub3);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (let i = 0; i < this._subscrip.length; i++) {
      this._subscrip[i].unsubscribe();
    }
  }
}
