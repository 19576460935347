import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {SpinnerService} from '../../services/spinner.service';
import {SortObject} from '../../classes/sort-object';
import {FilterService} from '../../services/filter.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnDestroy {

  private _list: any;
  private _pageItems: Array<any>;
  private _currentPage = 0;
  private _currentItemRange = 0;
  private _maxItemsPerPage = 100;
  private _itemCount: number;
  private _state: boolean;
  private _sortItems: Array<SortObject> = [];
  private _hasDelete = true;


  @Output() items: EventEmitter<Array<any>> = new EventEmitter();


  /* Todo Output der aktuellen Daten der Seite an Parent, diese wird dort :::::: ooooh man hat das lange gedauert */

  @HostListener('window:keyup', ['$event'])
  onKeyUp(e) {
    const keyCode = e.keyCode || e.which;
    switch (keyCode) {
      case 37:
        console.log('links');
        this.currentPage--;
        break;
      case 39:
        console.log('rechts');
        this.currentPage++;
        break;
    }
  }

  constructor(public spinnerInstance: SpinnerService, public filterService: FilterService) {
    const sub = this.spinnerInstance.status().subscribe(state => {
      this.state = state;
    });
  }

  public sliceKey(value) {
    return value.split(',');
  }

  public checkLink(link: string) {
    return !isNullOrUndefined(link) && link !== '';
  }

  ngOnInit() {
  }

  @Input()
  get list(): any {
    return this._list;
  }

  set list(value: any) {
    this._list = value;
    this._pageItems = value.slice(this.currentItemRange, this.currentItemRange + this.maxItemsPerPage);
    this.items.emit(this.pageItems);
    this.itemCount = value.length;
  }

  @Input()
  get maxItemsPerPage(): number {
    return this._maxItemsPerPage;
  }

  set maxItemsPerPage(value: number) {
    this._maxItemsPerPage = value;
  }

  @Input()
  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this.currentItemRange = this.maxItemsPerPage * (value - 1);
    this._pageItems = this.list.slice(this.currentItemRange, this.currentItemRange + this.maxItemsPerPage);
    this.items.emit(this.pageItems);
    this._currentPage = value;
  }

  get pageItems(): Array<any> {
    return this._pageItems;
  }

  set pageItems(value: Array<any>) {
    this._pageItems = value;
  }

  get currentItemRange(): number {
    return this._currentItemRange;
  }

  set currentItemRange(value: number) {
    this._currentItemRange = value;
  }

  get itemCount(): number {
    return this._itemCount;
  }

  set itemCount(value: number) {
    this._itemCount = value;
  }


  get state(): boolean {
    return this._state;
  }

  set state(value: boolean) {
    this._state = value;
  }


  get sortItems(): Array<SortObject> {
    return this._sortItems;
  }


  @Input()
  set sortItems(value: Array<SortObject>) {
    this._sortItems = value;
  }

  public changeDir(item: SortObject) {
    if (item.sortDir === 'asc') {
      item.sortDir = 'desc';
    } else if (item.sortDir === 'desc') {
      item.sortDir = 'asc';
    }

    this.filterService.sortObject.next(item);
  }

  @Input()
  get hasDelete(): boolean {
    return this._hasDelete;
  }

  set hasDelete(value: boolean) {
    this._hasDelete = value;
  }


  ngOnDestroy() {

  }


}
