import {TableRow} from './table-row';
import {Holiyday} from './holiyday';
import {WorkingType} from './working-type';

export class DeliveryTimes extends TableRow {

  private _setname: string;
  private _payload: { holidays: Array<any>, deliveryTypes: Array<any>, chineseHolidays: Array<any> } = {
    chineseHolidays: [],
    deliveryTypes: [],
    holidays: []
  };

  get setname(): string {
    return this._setname;
  }

  set setname(value: string) {
    this._setname = value;
  }

  get payload(): { holidays: Array<any>; deliveryTypes: Array<any>; chineseHolidays: Array<any> } {
    return this._payload;
  }

  set payload(value: { holidays: Array<any>; deliveryTypes: Array<any>; chineseHolidays: Array<any> }) {
    this._payload = value;
  }
}

