import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {SiteMeta} from '../classes/site-meta';

@Injectable()
export class SiteMetaDataModelService extends DataModel<SiteMeta> {

  constructor() {
    super(SiteMeta);
    // this.buildFormGroup(new SiteMeta());
  }

}
