import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class RequestTimeService {
  private _events = new BehaviorSubject<number>(Date.now());

  constructor() {
  }

  public start() {
    this._events.next(Date.now());
  }

  public finished() {
    this._events.next((Date.now() - this._events.getValue())  / 1000 );
  }

  public status(): Observable<number> {
    return this._events.asObservable();
  }

  public calcDiff(maxSecondsBetween, lastRequest): boolean {
    const diff = Math.abs((lastRequest - Date.now()) / 1000);
    return diff > maxSecondsBetween;
  }

}
