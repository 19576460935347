import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {AppConstants} from '../../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {ActivatedRoute} from '@angular/router';
import {UploadDataModelService} from '../../../data-models/upload-data-model.service';
import {FormControl} from '@angular/forms';
import {Upload} from '../../../classes/upload';

@Component({
  selector: 'app-upload-add',
  templateUrl: './upload-add.component.html',
  styleUrls: ['./upload-add.component.scss']
})
export class UploadAddComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.UPLOADS;
  private _types = [0, 1, 2, 3];
  private _typesWording = ['--Auswählen--', 'Presse', 'Download Patienten', 'Download Zahnarzt'];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: UploadDataModelService) {
    super(communicationService, activatedRoute, dataModel);
   
    this.dataModel.buildFormGroup(new Upload().toObject());
  }

  ngOnInit() {
  }

  public setFile($event: Event, control: FormControl) {

    const fileInput = $event.target as HTMLInputElement;
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      control.patchValue(fileReader.result);
    });
    fileReader.readAsDataURL(fileInput.files[0]);
  }

  get typesWording(): (string | string | string)[] {
    return this._typesWording;
  }

  set typesWording(value: (string | string | string)[]) {
    this._typesWording = value;
  }

  get types(): number[] {
    return this._types;
  }

  set types(value: number[]) {
    this._types = value;
  }

}
