import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {isUndefined} from 'util';

@Component({
  selector: 'app-ambulance-search',
  templateUrl: './ambulance-search.component.html',
  styleUrls: ['./ambulance-search.component.scss']
})
export class AmbulanceSearchComponent implements OnInit {

  constructor(public router: Router) {
  }

  ngOnInit() {
  }

  // todo verallgemeinern, route als Parameter übergeben, Search-Parent-Klasse?

  public filter(f: NgForm): void {
    const tmpObj = this._cleanObject(f);
    this.router.navigate(['/zahnaerzte'], {queryParams: tmpObj});
  }

  // todo auslagern

  private _cleanObject(f): any {
    const tmpObj = f.form.value;

    for (const key in f.form.value) {
      if (f.form.value.hasOwnProperty(key)) {
        const value = f.form.value[key];
        switch (typeof value) {
          case 'object':
            if (!isUndefined(value.length) && value.length < 0) {
              delete tmpObj[key];
            }
            break;
          default:
            if (value === '') {
              delete tmpObj[key];
            }
            break;
        }
      }
    }

    return tmpObj;
  }
}
