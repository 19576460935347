import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {Ambulanceview} from '../classes/ambulanceview';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AmbulanceviewDataModelService extends DataModel<Ambulanceview> {

  constructor() {
    super(Ambulanceview);
  }

}

