import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {isNullOrUndefined} from 'util';
import {WorkingType} from '../../../classes/working-type';
import {DeliveryTimesService} from '../../../services/delivery-times.service';
import {BaseComponent} from '../../../classes/base-component';
import {AppConstants} from '../../../../constants/app-constants';
import {CommunicatorService} from '../../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {weekdaysMin} from 'moment';

@Component({
  selector: 'app-delivery-times-calculator',
  templateUrl: './delivery-times-calculator.component.html',
  styleUrls: ['./delivery-times-calculator.component.scss']
})
export class DeliveryTimesCalculatorComponent implements OnInit, AfterViewInit {


  private _collectionDate: NgbDateStruct;
  private _workingType: WorkingType;
  private _appointment: NgbDateStruct;


  constructor(public  deliveryTimesService: DeliveryTimesService) {

  }

  public getTargetDate(selectedDate: Date, deliveryType): Date {
    let days = deliveryType.days[selectedDate.getDay() - 1];
    if (deliveryType.isChina) {
      // check if chinese holiday is in between

      for (let i = 0; i < this.deliveryTimesService.data.value.payload.chineseHolidays.length; i++) {
        const holidayDate = new Date(this.deliveryTimesService.data.value.payload.chineseHolidays[i].date);
        if (moment(holidayDate).isSame(selectedDate, 'day')) {
          const daysToAddForChinesHolidays = this.deliveryTimesService.data.value.payload.chineseHolidays[i].daysToAdd;
          days = days + daysToAddForChinesHolidays;
          console.log('Chinesischer Feiertag +' + daysToAddForChinesHolidays + ' Tage: ' + days);
          break;
        }
      }
    }

    // at first get day of week
    const dayOfWeek = moment(selectedDate).isoWeekday();
    let weekendDays = 0;
    const weeks = Math.floor((days + (5 % dayOfWeek)) / 5);

    // if friday always add 2 days for weekend
    if ((dayOfWeek === 5) && (days % 5 !== 0)) {
      weekendDays += 2;
    }

    // if ( ( ( (5 * weeks) - (dayOfWeek - 1 )) < days ) && ( days < ((5 * weeks) - (dayOfWeek - 1 ) + 4))) {
    weekendDays += weeks * 2;
    // }

    const daysToAdd = weekendDays + days;
    console.log('Wochentag: ' + dayOfWeek);
    console.log('Wochen: : ' + weeks);
    console.log('Wochenendtage (gesamt): ' + weekendDays);
    console.log('Tage addieren: (Werktage)' + days);
    console.log('Tage addieren: (gesamt)' + daysToAdd);
    // if targetDate is weekend day, then add 2 for another weekend

    let targetDate = new Date(moment(selectedDate).add(daysToAdd, 'days').toString());


    if (moment(targetDate).isoWeekday() > 5) {
      targetDate = moment(targetDate).add(2, 'days').toDate();
    }


    // check if holiday dates are between selected and target dates
    const daysToAddForHolidays = 0;
    for (let i = 0; i < this.deliveryTimesService.data.value.payload.holidays.length; i++) {
      const holidayDate = new Date(this.deliveryTimesService.data.value.payload.holidays[i].date);

      if (moment(holidayDate).isBetween(selectedDate, targetDate)) {
        targetDate = moment(targetDate).add(1, 'days').toDate();
        console.log(holidayDate + ' liegt dazwischen, also + 1');
      }

      // check if new targetDate is holidayDate
      if (moment(holidayDate).isSame(targetDate, 'day')) {
        targetDate = moment(targetDate).add(1, 'days').toDate();
        console.log('Enddatum um 1 erhöht, da es auf das targetDate gefallen ist');
      }

      // check if new targetDate is weekendDate
      if (moment(targetDate).isoWeekday() > 5) {
        targetDate = moment(targetDate).add(2, 'days').toDate();
      }
    }


    // no we need to check if we are on a holidaydate

    return targetDate;
  }


  public disableDays() {

    const delivery: DeliveryTimesCalculatorComponent = arguments[0][0];
    const date: NgbDateStruct = arguments[1];
    const d = new Date(date.year, date.month - 1, date.day);

    for (let i = 0; i < delivery.deliveryTimesService.data.value.payload.holidays.length; i++) {
      const holiday = new Date(delivery.deliveryTimesService.data.value.payload.holidays[i].date);
      if (d.getFullYear() === holiday.getFullYear() && holiday.getMonth() === d.getMonth() && d.getDate() === holiday.getDate()) {
        return true;
      }
    }
    return d.getDay() === 0 || d.getDay() === 6;

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    /*
    setTimeout(() => {
      console.log(this.deliveryTimesService.data.value.payload.workingsdays[0]);
      this.workingType = this.deliveryTimesService.data.value.payload.workingsdays[0];
    }, 1200);
    */
  }

  public stopProp($event) {
    $event.stopPropagation();
  }

  public toggle(d: NgbInputDatepicker, holi: NgbInputDatepicker, chineseHoli: NgbInputDatepicker) {
    if (d.isOpen()) {
      d.toggle();
    }
    if (holi.isOpen()) {
      holi.toggle();
    }
    if (chineseHoli.isOpen()) {
      chineseHoli.toggle();
    }
  }

  public formatDate(date: string | NgbDateStruct): NgbDateStruct {
    if (typeof date === 'string') {
      const dateArray = date.split('.');
      return {
        day: parseInt(dateArray[0], 10),
        month: parseInt(dateArray[1], 10),
        year: parseInt(dateArray[2], 10)
      };
    }
    return date;
  }

  public toGermanDate(date: NgbDateStruct): string {
    if (isNullOrUndefined(date) || isNullOrUndefined(date.day) || isNullOrUndefined(date.month) || isNullOrUndefined(date.year)) {
      return '';
    }
    return ('0' + date.day).slice(-2) + '.' + ('0' + date.month).slice(-2) + '.' + date.year;
  }

  public setDateStruct($event, date: any) {
    const keyCode = $event.which || $event.keyCode;
    if (keyCode === 13) {
      this.collectionDate = this.formatDate(date);
    }
  }

  public toDateString(date: Date) {
    return this.toGermanDate({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    });
  }

  get collectionDate(): any {
    return this._collectionDate;
  }

  set collectionDate(value: any) {
    this._collectionDate = value;

    if (isNullOrUndefined(this.workingType)) {
      return;
    }
    if (isNullOrUndefined(value) || isNullOrUndefined(value.year) || isNullOrUndefined(value.month) || isNullOrUndefined(value.day)) {
      return;
    }

    const date = new Date(value.year, value.month - 1, value.day);
    const appoint = this.getTargetDate(date, this.workingType);
    this.appointment = {
      year: appoint.getFullYear(),
      month: appoint.getMonth() + 1,
      day: appoint.getDate()
    };
  }


  get workingType(): any {
    return this._workingType;
  }

  set workingType(value: any) {
    this._workingType = value;

    if (isNullOrUndefined(this.collectionDate)) {
      return;
    }
    const date = new Date(this.collectionDate.year, this.collectionDate.month - 1, this.collectionDate.day);
    const appoint = this.getTargetDate(date, value);
    this.appointment = {
      year: appoint.getFullYear(),
      month: appoint.getMonth() + 1,
      day: appoint.getDate()
    };
  }

  get appointment(): NgbDateStruct {
    return this._appointment;
  }

  set appointment(value: NgbDateStruct) {
    this._appointment = value;
  }


}
