import {Input, OnInit} from '@angular/core';

export class ItemsComponent<T> implements OnInit {
  private _data: T;

  constructor() {

  }

  ngOnInit() {
  }

  @Input()
  get data(): T {
    return this._data;
  }

  set data(value: T) {
    this._data = value;
  }

}
