import {Component, Input, OnInit} from '@angular/core';
import {FilterItem} from '../../classes/filter-item';
import {FilterService} from '../../services/filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  private _filterItems: Array<FilterItem> = [];

  @Input()
  get filterItems() {
    return this._filterItems;
  }

  set filterItems(value) {
    this._filterItems = value;
  }

  public changeValue(item: FilterItem) {
    switch (typeof item.value) {
      case 'boolean':
        item.value = !item.value;
        break;
    }
    this._filterService.filterObject.next(this.filterItems);
  }

  constructor(private _filterService: FilterService) {
  }

  ngOnInit() {
  }

}
