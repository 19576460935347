import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/guards/auth.guard';
import { DentnetIndexResolverService } from '../app/resolvers/dentnet-index-resolver.service';
import { DentnetItemResolverService } from '../app/resolvers/dentnet-item-resolver.service';
import { DownloadItemResolverService } from '../app/resolvers/download-item-resolver.service';
import { InsurancesItemResolverService } from '../app/resolvers/insurances-item-resolver.service';
import { SiteMetaItemResolverService } from '../app/resolvers/site-meta-item-resolver.service';
import { AmbulanceAddComponent } from '../app/sites/ambulances/ambulance-add/ambulance-add.component';
import { AmbulanceEditComponent } from '../app/sites/ambulances/ambulance-edit/ambulance-edit.component';
import { AmbulanceSearchComponent } from '../app/sites/ambulances/ambulance-search/ambulance-search.component';
import { AmbulancesComponent } from '../app/sites/ambulances/ambulances.component';
import { DashboardComponent } from '../app/sites/dashboard/dashboard.component';
import { DentnetEditComponent } from '../app/sites/dentnet/dentnet-edit/dentnet-edit.component';
import { DentnetComponent } from '../app/sites/dentnet/dentnet.component';
import { HealthinsuranceSearchComponent } from '../app/sites/healthinsurances/healthinsurance-search/healthinsurance-search.component';
import { HealthinsurancesComponent } from '../app/sites/healthinsurances/healthinsurances.component';
import { InsuranceAddComponent } from '../app/sites/insurances/insurance-add/insurance-add.component';
import { InsuranceEditComponent } from '../app/sites/insurances/insurance-edit/insurance-edit.component';
import { InsurancesComponent } from '../app/sites/insurances/insurances.component';
import { KzvImportComponent } from '../app/sites/kzv-import/kzv-import.component';
import { LoginComponent } from '../app/sites/login/login.component';
import { PatientSearchComponent } from '../app/sites/patients/patient-search/patient-search.component';
import { PatientsComponent } from '../app/sites/patients/patients.component';
import { SearchlogsComponent } from '../app/sites/searchlogs/searchlogs.component';
import { DeliveryTimesCalculatorOnlyComponent } from '../app/sites/settings/delivery-times-calculator-only/delivery-times-calculator-only.component';
import { DeliverytimesComponent } from '../app/sites/settings/deliverytimes/deliverytimes.component';
import { SettingsComponent } from '../app/sites/settings/settings.component';
import { SiteMetaEditComponent } from '../app/sites/site-metas/site-meta-edit/site-meta-edit.component';
import { SiteMetasComponent } from '../app/sites/site-metas/site-metas.component';
import { UploadAddComponent } from '../app/sites/uploads/upload-add/upload-add.component';
import { UploadEditComponent } from '../app/sites/uploads/upload-edit/upload-edit.component';
import { UploadsComponent } from '../app/sites/uploads/uploads.component';

const routes: Routes = [
  {
    path     : '',
    component: LoginComponent
  },
  {
    path     : 'dentnet',
    component: DentnetComponent,
    resolve  : {
      dentnet: DentnetIndexResolverService
    }
  },
  {
    path     : 'dentnet/edit/:uname',
    component: DentnetEditComponent,
    resolve  : {
      dentnet: DentnetItemResolverService
    }
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'zahnaerzte',
    component  : AmbulancesComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'zahnaerzte/suche',
    component  : AmbulanceSearchComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'kassen',
    component  : HealthinsurancesComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'kassen/suche',
    component  : HealthinsuranceSearchComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'patienten',
    component  : PatientsComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'patienten/suche',
    component  : PatientSearchComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'zahnaerzte/add',
    component  : AmbulanceAddComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'zahnaerzte/edit/:uname',
    component  : AmbulanceEditComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'zahnaerzte/edit/:id',
    component  : AmbulanceEditComponent
  },
  {
    canActivate: [ AuthGuard ],
    path       : 'dashboard',
    component  : DashboardComponent
  },
  {
    path     : 'einstellungen',
    component: SettingsComponent
  },
  {
    path     : 'settings/deliverytimescalculator',
    component: DeliverytimesComponent
  },
  {
    path     : 'external/deliverytimescalculator',
    component: DeliveryTimesCalculatorOnlyComponent
  },
  {
    path     : 'dentnet/sitemetas',
    component: SiteMetasComponent
  },
  {
    path     : 'dentnet/sitemetas/edit/:uname',
    component: SiteMetaEditComponent,
    resolve  : {
      dentnet: SiteMetaItemResolverService
    }
  },
  {
    path     : 'dentnet/healthinsurances',
    component: InsurancesComponent
  },
  {
    path     : 'dentnet/healthinsurances/edit/:uname',
    component: InsuranceEditComponent,
    resolve  : {
      insurance: InsurancesItemResolverService
    }
  },
  {
    path     : 'dentnet/healthinsurances/add',
    component: InsuranceAddComponent
  },
  {
    path     : 'dentnet/downloads',
    component: UploadsComponent
  },
  {
    path     : 'dentnet/downloads/edit/:uname',
    component: UploadEditComponent,
    resolve  : {
      dentnet: DownloadItemResolverService
    }
  },
  {
    path     : 'dentnet/downloads/add',
    component: UploadAddComponent
  },
  {
    path     : 'dentnet/searchlogs',
    component: SearchlogsComponent
  },
  {
    path     : 'kzvimport',
    component: KzvImportComponent
  }
];

@NgModule ( {
  imports: [ RouterModule.forRoot ( routes /*{enableTracing: true}*/ ) ],
  exports: [ RouterModule ]
} )
export class RoutingModule {

}
