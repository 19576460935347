import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppConstants} from '../../../../constants/app-constants';
import {BaseComponent} from '../../../classes/base-component';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {SurgeryDataModelService} from '../../../data-models/surgery-data-model.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-dentnet-edit',
  templateUrl: './dentnet-edit.component.html',
  styleUrls: ['./dentnet-edit.component.scss']
})
export class DentnetEditComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.SURGERIES;

  constructor(public communication: DentnetCommunicatorService, public dataModel: SurgeryDataModelService, public activatedRoute: ActivatedRoute) {
    super(communication, activatedRoute, dataModel);
  }

  public view() {
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParams['token'])) {
      AppConstants.JWT_TOKEN_DENTNET = this.activatedRoute.snapshot.queryParams['token'];
    }
    const sub = this.communication.view(this.endpoint, this.dataModel, this.activatedRoute.snapshot.params['uname']).subscribe((response) => {
      console.log(response);
    });

    this.subscriptions.push(sub);
  }

  ngOnInit() {

  }

}
