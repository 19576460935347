import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from '../../../constants/app-constants';
import { KzvCsvDataModelService } from '../../data-models/kzv-csv-data-model.service';
import { KzvService } from '../../services/kzv.service';

@Component ( {
  selector   : 'app-kzv-import',
  templateUrl: './kzv-import.component.html',
  styleUrls  : [ './kzv-import.component.scss' ]
} )
export class KzvImportComponent implements OnInit, OnDestroy {

  @ViewChild ( 'content', { static: false } ) content;

  private isNewDentistVisible          = false;
  private _manualSub: Subscription;
  private compareAmbulance: object;
  private subscription: Subscription[] = [];
  public myservice: String             = AppConstants.SERVICE + 'ambulances/view/';
  public changed                       = false;

  @ViewChild ( 'loglist', { static: false } ) loglist;

  constructor ( public KzvService: KzvService, public kzvCsv: KzvCsvDataModelService ) {
  }

  ngOnInit () {
    this.subscription.push ( this.kzvCsv.observeManualUpdateData ()
                                 .subscribe ( ( value ) => {
                                 } ) );

    this.subscription.push ( this.kzvCsv.oberserveMessages ()
                                 .subscribe ( ( next ) => {
                                   console.log ( 'MESSAGE', this.loglist );
                                   if ( this.loglist ) {
                                     this.loglist.nativeElement.scrollTop = this.loglist.nativeElement.scrollHeight + 10;
                                   }
                                 } ) );
  }

  ngOnDestroy () {
    this.subscription.forEach ( ( value: Subscription ) => {
      value.unsubscribe ();
    } );
  }

  importData () {
    this.KzvService.startImport ();
  }

  public onCompleteChanged ( changed: boolean ) {
    this.changed = changed;
    console.log ( changed );
  }

  public dismissCompare () {
    this.kzvCsv.addMessage ( 'Der manuelle Vergleich wurde abgelehnt.' );
    this.KzvService.startImport ();
    this.KzvService.manualCompare.next ( null );
    this.KzvService.importStatistics.dismissedManually.next (
      this.KzvService.importStatistics.dismissedManually.getValue () + 1 );
  }

  public acceptCompare () {
    this.KzvService.updateItem ();
    this.KzvService.manualCompare.next ( null );
  }

  public createNewFromCompare () {
    this.KzvService.addItemToParkingList ();
    this.KzvService.manualCompare.next ( null );
  }

  public addDentist () {
    //this.isNewDentistVisible = !this.isNewDentistVisible;

    const newDentist = {
      salutation: '',
      title     : '',
      firstname : '',
      name      : '',
      kzvnumber : '',
      mobile    : '',
      isnew     : true
    };

    this.KzvService.manualCompare.value.dentists.push ( newDentist );
  }

  removeNewDentist ( dentist ) {
    let arr = this.KzvService.manualCompare.value.dentists;

    arr.splice ( arr.indexOf ( dentist ), 1 );
    this.KzvService.manualCompare.value.dentists = arr;
  }

  public update () {
    this.KzvService.update ();
    this.KzvService.manualCompare.next ( null );
  }

  goto ( id ) {
    window.open ( this.myservice + id, '_blank' );
  }

  public reload () {
    window.location.reload ();
  }
}
