import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sort',
  pure: false
})
export class SortPipe implements PipeTransform {

  transform(array: Array<any>, attribute: string, direction: string): Array<string> {
    if (attribute === '' || direction === '') {
      return array;
    }
    if (direction === 'asc') {
      array.sort((a: any, b: any) => {
        if (a[attribute] < b[attribute]) {
          return -1;
        } else if (a[attribute] > b[attribute]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    } else if (direction === 'desc') {
      array.sort((a: any, b: any) => {
        if (a[attribute] > b[attribute]) {
          return -1;
        } else if (a[attribute] < b[attribute]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    }
  }

}
