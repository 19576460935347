import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SortObject} from '../classes/sort-object';

@Injectable()
export class FilterService {


  private _filterObject: BehaviorSubject<any> = new BehaviorSubject({});
  private _sortObject: BehaviorSubject<SortObject> = new BehaviorSubject(new SortObject());

  constructor() {
  }

  get filterObject(): BehaviorSubject<any> {
    return this._filterObject;
  }

  set filterObject(value: BehaviorSubject<any>) {
    this._filterObject = value;
  }

  public filterStatus(): Observable<any> {
    return this.filterObject.asObservable();
  }

  public sortStatus(): Observable<SortObject> {
    return this.sortObject.asObservable();
  }

  public sortItems(dataItemsFiltered) {
    let itemsSortet = Object.assign([], dataItemsFiltered.value);

    itemsSortet = itemsSortet.sort((a, b) => {
      switch (this._sortObject.value.sortDir) {
        case 'asc':
          switch (this._sortObject.value.sortType) {
            case 'string':
              if (a[this._sortObject.value.sortAttr] < b[this._sortObject.value.sortAttr]) {
                return -1;
              }
              if (a[this._sortObject.value.sortAttr] > b[this._sortObject.value.sortAttr]) {
                return 1;
              }
              return 0;
            case 'number':
              return a[this._sortObject.value.sortAttr] - b[this._sortObject.value.sortAttr];
          }
          break;
        case 'desc':

          switch (this._sortObject.value.sortType) {
            case 'string':
              if (b[this._sortObject.value.sortAttr] < a[this._sortObject.value.sortAttr]) {
                return -1;
              }
              if (b[this._sortObject.value.sortAttr] > a[this._sortObject.value.sortAttr]) {
                return 1;
              }
              return 0;
            case 'number':
              return b[this._sortObject.value.sortAttr] - a[this._sortObject.value.sortAttr];
          }
      }
    });
    dataItemsFiltered.next(itemsSortet);
  }

  public filterItems(dataItems, dataItemsFiltered) {
    let filteredItems = Object.assign([], dataItems.value);

    for (let i = 0; i < this.filterObject.value.length; i++) {
      const obj = this.filterObject.value[i];
      switch (typeof obj.value) {
        case 'number':
        case 'boolean':
          filteredItems = filteredItems.filter((val, n) => {
            let tmpVal = val[obj.key];
            if (typeof tmpVal === 'number') {
              tmpVal = !!val[obj.key];
            }
            if (!obj.value) {
              return val;
            } else if (tmpVal === obj.value) {
              return val;
            }
          });
          break;
        case 'string':
          filteredItems = filteredItems.filter((val, n) => {
            if (obj.value === '') {
              return val;
            } else if (val[obj.value] === obj.value) {
              return val;
            }
          });
          break;
      }
    }
    dataItemsFiltered.next(filteredItems);
  }


  get sortObject(): BehaviorSubject<SortObject> {
    return this._sortObject;
  }

  set sortObject(value: BehaviorSubject<SortObject>) {
    this._sortObject = value;
  }

}
