import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {ActivatedRoute} from '@angular/router';
import {SiteMetaDataModelService} from '../../../data-models/site-meta-data-model.service';
import {AppConstants} from '../../../../constants/app-constants';

@Component({
  selector: 'app-site-meta-edit',
  templateUrl: './site-meta-edit.component.html',
  styleUrls: ['./site-meta-edit.component.scss']
})
export class SiteMetaEditComponent extends BaseComponent implements OnInit {


  protected endpoint = AppConstants.METAS;
  constructor(communcationService: DentnetCommunicatorService, activatedRoute: ActivatedRoute, dataModel: SiteMetaDataModelService) {
    super(communcationService, activatedRoute, dataModel);
    this.view();
  }

  ngOnInit() {

  }

  checkForm() {
    console.log(this.dataModel.formGroup.value);
  }

}
