import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {AppConstants} from '../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {MessageService} from '../../services/message.service';
import {SurgeryDataModelService} from '../../data-models/surgery-data-model.service';
import {Image} from '../../classes/image';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {Message} from '../../classes/message';
import {TransparentSpinnerService} from '../../services/transparent-spinner.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-imgadd',
  templateUrl: './imgadd.component.html',
  styleUrls: ['./imgadd.component.scss']
})
export class ImgaddComponent extends BaseComponent implements OnInit, OnDestroy {

  private _tmpImg: Image = new Image();
  private _profileImg: Image = new Image();
  private _images: FormArray;
  private _type: string;
  private _group: FormGroup;
  private _imgSub: Subscription;
  protected endpoint = AppConstants.IMAGES;

  constructor(public communicationService: DentnetCommunicatorService,
              public activatedRoute: ActivatedRoute,
              public messageService: MessageService,
              public dataModel: SurgeryDataModelService,
              public formBuilder: FormBuilder,
              public transparentSpinner: TransparentSpinnerService) {
    super(communicationService, activatedRoute, dataModel);
  }

  ngOnInit() {
    this.order();
    this._checkImageLength();

    this._imgSub = this.transparentSpinner.status().subscribe(response => {
      setTimeout(() => {
        this.order();
      }, 0);
    });

  }


  ngOnDestroy() {
    super.ngOnDestroy();
    if (!isNullOrUndefined(this._imgSub)) {
      this._imgSub.unsubscribe();
    }
  }

  public order() {

    const images = Object.assign([], this.images.value as Image[]);

    images.forEach((value) => {
      if (typeof value.ordering === 'string' || value.ordering === '' && value.type === 'gallery') {
        value.ordering = Math.max.apply(
          Math, images.map((image) => {
            return image.ordering;
          })
        );
      }
    });

    images.sort((a, b) => {
      return a.ordering - b.ordering;
    });

    this.images.reset();
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      this.images.push(this.formBuilder.group(img));
    }

  }

  public replaceProfile(img: Image, $event) {
    this._checkImageLength();
    if (!isNullOrUndefined($event)) {
      img.file = $event;
    }
    if (img.filename === '' || isNullOrUndefined(img.filename)) {
      const mes = new Message();
      mes.text = 'Profilbild konnte nicht hinzugefügt werden (Kein Dateiname vorhanden)';
      mes.type = 'hint';
      this.messageService.addMessage(mes);
      return;
    }

    img.type = 'profile';
    const mes = new Message();
    mes.text = 'Profilbild ersetzt';
    mes.type = 'hint';

    const index = this._checkIfProfileImageExists();

    if (index > -1) {
      this.images.controls[index].patchValue({
        file: img.file,
        filename: img.filename,
        type: 'profile'
      });
      this.messageService.addMessage(mes);
    } else {
      this.images.push(this.formBuilder.group(img.toObject()));
      mes.text = 'Profilbild hinzugefügt';
      this.messageService.addMessage(mes);
    }


    this.dataModel.data.value.init(this.group.value);

  }

  private _checkImageLength() {
    if (this.dataModel.data.value.images.length >= 0) {
      this.dataModel.data.value.images.push(new Image());
    }
  }

  private _checkIfProfileImageExists(): number {
    for (let i = 0; i < this.images.controls.length; i++) {
      const image = this.images.controls[i].value;
      if (image.type === 'profile') {
        return i;
      }
    }
    return -1;
  }

  private _checkImages(items: FormArray) {
    for (let i = 0; i < items.controls.length; i++) {
      const item = items.controls[i].value;
      if (isNullOrUndefined(item.type) || item.type === '' || isNullOrUndefined(item.filename) || item.filename === '') {
        items.removeAt(i);
      }
    }
  }

  public addImage(img: Image, $event?) {
    this._checkImageLength();
    if (!isNullOrUndefined($event)) {
      img.file = $event;
    }

    const mes = new Message();
    mes.type = 'hint';

    if (img.filename === '' || isNullOrUndefined(img.filename)) {
      mes.text = 'Profilbild konnte nicht hinzugefügt werden (Kein Dateiname vorhanden)';
      this.messageService.addMessage(mes);
      return;
    }

    for (let i = 0; i < this.images.controls.length; i++) {
      if (img.filename === this.images.controls[i].value.filename) {
        img.filename = (this.images.controls.length + 1) + img.filename;
      }
    }

    const imgItem = new Image();
    imgItem.type = 'gallery';
    imgItem.ordering = this.images.controls.length + 1;
    imgItem.filename = this.tmpImg.filename;
    imgItem.file = this.tmpImg.file;
    imgItem.alt = '';
    imgItem.title = '';

    this.images.push(this.formBuilder.group(imgItem.toObject()));

    mes.text = 'Gallerie Bild hinzugefügt';

    this.messageService.addMessage(mes);
    this.tmpImg = new Image();

    this.dataModel.data.value.init(this.group.value);
    this.dataModel.data.next(this.dataModel.data.value);

    this.order();
  }

  public setSource(image: Image) {
    if (!isNullOrUndefined(image.file) && image.file !== '') {
      return image.file;
    } else {
      return image.uri;
    }
  }

  public drag($event = null, item: FormControl) {
    const elems = document.getElementsByClassName('drag-able-item');
    for (let i = 0; i < elems.length; i++) {
      elems[i].classList.add('started-dragging');
    }

    if (!isNullOrUndefined($event)) {
      console.log($event.target.classList.add('currently-dragging'));
      $event.dataTransfer.setData('item', JSON.stringify(item.value));
    }
  }

  public drop($event = null, item: FormControl) {
    if (!isNullOrUndefined($event)) {
      $event.preventDefault();
      let data: any = $event.dataTransfer.getData('item');
      data = JSON.parse(data);

      let tmpId = 0;
      for (let i = 0; i < this.images.controls.length; i++) {
        const tmpImg = this.images.controls[i].value;
        if (tmpImg.filename === data.filename) {
          tmpId = i;
        }
      }
      const control = this.images.at(tmpId);

      for (let i = 0; i < this.images.controls.length; i++) {
        const tmpImg = this.images.controls[i].value;
        if (tmpImg.filename === item.value.filename) {
          const oldId = this.images.controls[i].value.ordering;

          this.images.controls[i].patchValue({
            ordering: control.value.ordering === oldId ? control.value.ordering + 1 : control.value.ordering
          });

          control.patchValue({
            ordering: oldId
          });
        }
      }
      this.order();
    }
  }

  public setDragOver($event?) {
    if (!isNullOrUndefined($event)) {
      const t = $event.target;
      if (t.classList.contains('drag-able-item')) {
        t.classList.add('over');
      }
    }
  }

  public unsetDragOver($event?) {
    if (!isNullOrUndefined($event)) {
      const t = $event.target;
      t.classList.remove('over');
    }
  }

  public unsetDrag($event?) {
    const elems = document.getElementsByClassName('drag-able-item');
    for (let i = 0; i < elems.length; i++) {
      elems[i].classList.remove('currently-dragging');
      elems[i].classList.remove('started-dragging');
    }
  }

  public allowDrop($event = null) {
    if (!isNullOrUndefined($event)) {
      $event.preventDefault();
    }
  }

  public deleteFromArray(filename) {

    for (let i = 0; i < this.images.value.length; i++) {
      if (this.images.controls[i].value.filename === filename) {
        this.images.removeAt(i);
      }
    }
    this.dataModel.buildFormGroup(this.dataModel.data.value.init(this.group.value));
    this._checkImageLength();
  }

  public delete(item) {

    if (isNullOrUndefined(item.id)) {
      if (confirm('Sind Sie sich wirklich sicher?')) {
        this.deleteFromArray(item.filename);
      }
      return;
    }
    if (confirm('Sind Sie sich wirklich sicher?')) {

      const sub = this.communication
        .delete(this.endpoint, item.id, this.dataModel)
        .subscribe((response) => {
          if (response.success) {
            this.deleteFromArray(item.filename);
          }
      });
      this.subscriptions.push(sub);
    }
  }

  @Input()
  get group(): FormGroup {
    return this._group;
  }

  set group(value: FormGroup) {
    this._group = value;
  }

  get profileImg(): Image {
    return this._profileImg;
  }

  set profileImg(value: Image) {
    this._profileImg = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
    this.tmpImg.type = value;
  }

  public getFormGroupName(img) {
    for (let i = 0; i < this.images.controls.length; i++) {
      const imgVal = this.images.controls[i].value;
      if (isNullOrUndefined(img.value.id) && imgVal.ordering === img.value.ordering) {
        return i;
      } else  if (!isNullOrUndefined(img.value.id) && imgVal.id === img.value.id) {
        return i;
      }
    }
    return - 1;
  }

  public getFileData(e) {
    this.tmpImg.file = e;
  }

  public getImageData(e: File) {
    this.tmpImg.filename = e.name;
  }

  get tmpImg(): Image {
    return this._tmpImg;
  }

  set tmpImg(value: Image) {
    this._tmpImg = value;
  }


  get images(): FormArray {
    const images = this.group.get('images') as FormArray;
    this._checkImages(images);
    return images;
  }

  set images(value: FormArray) {
    this._images = value;
  }

}
