import {Injectable} from '@angular/core';
import {DataModel} from './data-model';
import {Ambulance} from '../classes/ambulance';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AmbulanceDataModelService extends DataModel<Ambulance> {

  constructor() {
    super(Ambulance);
  }

}

