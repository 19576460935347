import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Surgery} from '../../../../classes/surgery';
import {ItemsComponent} from '../../../../classes/items-component';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'app-dentnet-items',
  templateUrl: './dentnet-items.component.html',
  styleUrls: ['./dentnet-items.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DentnetItemsComponent extends ItemsComponent<Surgery> implements OnInit, AfterViewInit {


  constructor() {
    super();
    this.data = new Surgery();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }


}
