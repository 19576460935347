import {isFunction} from 'rxjs/util/isFunction';
import {BehaviorSubject} from 'rxjs';
import {TableRow} from '../classes/table-row';
import {isObject} from 'rxjs/util/isObject';
import {isArray} from 'rxjs/util/isArray';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IT} from '../interfaces/i-t';

export class DataModel<T> {
  protected _dataItems: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected _data: BehaviorSubject<T>;
  private _dataItemsFiltered: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  private _lastRequest = 0;
  private _maxSecondsBetween = 0;

  private _formGroup: FormGroup = new FormGroup({});

  private _fb = new FormBuilder();

  constructor(cIT: IT<T>) {
    this.data = new BehaviorSubject(new cIT());
  }

  get data(): BehaviorSubject<T> {
    return this._data;
  }

  set data(value: BehaviorSubject<T>) {
    this._data = value;
  }

  get dataItems(): BehaviorSubject<T[]> {
    return this._dataItems;
  }

  set dataItems(value: BehaviorSubject<T[]>) {
    this._dataItems = value;
  }

  public add(item: T, callback?: Function) {
    // this.dataItems.;
    this._dataItems.value.push(item);
    if (!!callback && isFunction(callback)) {
      callback();
    }
  }

  public remove(item: T, callback?: Function) {
    const index = this.dataItems.value.indexOf(item);
    if (index > -1) {
      this.dataItems.value.splice(index, 1);
    }
    if (!!callback && isFunction(callback)) {
      callback();
    }
  }

  public buildFormGroup(object: any) {
    const anyObj = object; //.toObject();

    try {
      const newFormGroup = {};
      for (const key in anyObj) {
        if (Object(anyObj).hasOwnProperty(key)) {
          if (isArray(anyObj[key])) {
            newFormGroup[key] = this._fb.array([]);
            for (let i = 0; i < anyObj[key].length; i++) {
              newFormGroup[key].push(this._fb.group(anyObj[key][i]));
            }
          } else if (isObject(anyObj[key])) {
            newFormGroup[key] = this._fb.group(anyObj[key]);

          } else {
            newFormGroup[key] = anyObj[key];
          }
        }
      }
      this.formGroup = this._fb.group(newFormGroup);
    } catch (e) {
      console.log(e);
    }

  }

  get lastRequest(): number {
    return this._lastRequest;
  }

  set lastRequest(value: number) {
    this._lastRequest = value;
  }

  get maxSecondsBetween(): number {
    return this._maxSecondsBetween;
  }

  set maxSecondsBetween(value: number) {
    this._maxSecondsBetween = value;
  }

  get dataItemsFiltered(): BehaviorSubject<T[]> {
    return this._dataItemsFiltered;
  }

  set dataItemsFiltered(value: BehaviorSubject<T[]>) {
    this._dataItemsFiltered = value;
  }

  get formGroup(): FormGroup {
    return this._formGroup;
  }

  set formGroup(value: FormGroup) {
    this._formGroup = value;
  }


}
