
import {EnvironmentSwitch} from '../app/classes/environment-switch';
import {environment} from '../environments/environment';

export class AppConstants {
  public static readonly SERVICE = environment.myservice;
  public static readonly AMBULANCES_SERVICE = AppConstants.SERVICE + 'ambulances';
  public static readonly AMBULANCES = 'ambulances';
  public static readonly AMBULANCES_VIEW = 'ambulancesview';
  public static readonly SURGERIES = 'surgeries';
  public static readonly IMAGES = 'images';
  public static readonly METAS = 'metas';
  public static readonly INSURANCES = 'insurances';
  public static readonly DELIVERYTIMES = 'service/deliveryholidays';
  public static readonly UPLOADS = 'uploads';
  public static readonly SEARCHLOGS = 'searchlogs';


  public static readonly SERVICE_DENTNET =  environment.dentnetservice;
  public static JWT_TOKEN_DENTNET = environment.dentnetsearchtoken;

}
