import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {isUndefined} from 'util';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public authService: AuthService, public router: Router) {

  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

    let $return = false;
    const token = localStorage.getItem('token');
    if (!isUndefined(token) && token !== null) {
      $return = true;
      this.authService.show();
    } else {
      this.authService.hide();
      this.router.navigate(['/']);
    }

    return $return;
  }
}
