import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {SiteMetaDataModelService} from '../../data-models/site-meta-data-model.service';
import {AppConstants} from '../../../constants/app-constants';
import {DentnetCommunicatorService} from '../../services/dentnet-communicator.service';
import {FilterService} from '../../services/filter.service';
import {Subscription} from 'rxjs';
import {SiteMeta} from '../../classes/site-meta';

@Component({
  selector: 'app-site-metas',
  templateUrl: './site-metas.component.html',
  styleUrls: ['./site-metas.component.scss']
})
export class SiteMetasComponent extends BaseComponent implements OnInit, OnDestroy {

  private _fulltextSearch: string;
  protected endpoint = AppConstants.METAS;
  private _subscrip: Subscription[] = [];
  public sortObject = [
    {'sortAttr': 'slug', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Slug'},
    {'sortAttr': 'title', 'sortDir': 'asc', 'sortType': 'string', 'wording': 'Title'},
  ];
  public pageItems: Array<SiteMeta> = [];

  constructor(public communicationService: DentnetCommunicatorService, public activatedRoute: ActivatedRoute, public dataModel: SiteMetaDataModelService, public filterService: FilterService) {
    super(communicationService, activatedRoute, dataModel);
    this.index();

    const sub2 = this.filterService.sortStatus().subscribe((response) => {
      this.filterService.sortItems(this.dataModel.dataItemsFiltered);
    });
    this._subscrip.push(sub2);
  }

  ngOnInit() {
  }

  get fulltextSearch(): string {
    return this._fulltextSearch;
  }

  set fulltextSearch(value: string) {
    this._fulltextSearch = value;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    for (let i = 0; i < this._subscrip.length; i++) {
      this._subscrip[i].unsubscribe();
    }
  }

}
