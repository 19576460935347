export class FilterList {
  private _isimex = true;
  private _isdentnet = true;
  private _isdentnetpro = true;
  private _isactive = true;
  private _isauthorized = true;
  private _isblacklist = true;
  private _iszipsalesactive = true;
  private _isdhlallowed = true;
  private _isdeleted = true;
  private _nocustomer = true;

  get nocustomer(): boolean {
    return this._nocustomer;
  }

  set nocustomer(value: boolean) {
    this._nocustomer = value;
  }

  get isdhlallowed(): boolean {
    return this._isdhlallowed;
  }

  get isdeleted(): boolean {
    return this._isdeleted;
  }

  set isdeleted(value: boolean) {
    this._isdeleted = value;
  }

  set isdhlallowed(value: boolean) {
    this._isdhlallowed = value;
  }

  get iszipsalesactive(): boolean {
    return this._iszipsalesactive;
  }

  set iszipsalesactive(value: boolean) {
    this._iszipsalesactive = value;
  }

  get isblacklist(): boolean {
    return this._isblacklist;
  }

  set isblacklist(value: boolean) {
    this._isblacklist = value;
  }

  get isauthorized(): boolean {
    return this._isauthorized;
  }

  set isauthorized(value: boolean) {
    this._isauthorized = value;
  }

  get isactive(): boolean {
    return this._isactive;
  }

  set isactive(value: boolean) {
    this._isactive = value;
  }

  get isdentnetpro(): boolean {
    return this._isdentnetpro;
  }

  set isdentnetpro(value: boolean) {
    this._isdentnetpro = value;
  }

  get isdentnet(): boolean {
    return this._isdentnet;
  }

  set isdentnet(value: boolean) {
    this._isdentnet = value;
  }

  get isimex(): boolean {
    return this._isimex;
  }

  set isimex(value: boolean) {
    console.log(this);
    this._isimex = value;
  }
}
