import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class KzvCsvDataModelService {

  get mappedItems(): Array<Object> {
    return this._mappedItems;
  }

  set mappedItems(value: Array<Object>) {
    this._mappedItems = value;
  }

  private _items: Array<Array<string>> = [];

  private _mappedItems: Array<object> = [];

  private _messages: BehaviorSubject<Array<string>> = new BehaviorSubject([]);

  private _manualUpdateData: BehaviorSubject<object> = new BehaviorSubject({});



  get items(): Array<Array<string>> {
    return this._items;
  }


  set items(value: Array<Array<string>>) {
    this._items = value;
  }

  constructor() {
  }

  public addMessage(message: string) {
    const arr = this._messages.getValue();
    arr.push(message);
    this._messages.next(arr)
  }


  public oberserveMessages(): Observable<Array<string>> {
    return this._messages.asObservable();
  }


  public addManualUpdateData(data: object) {
    this._manualUpdateData.next(data);
  }


  public observeManualUpdateData(): Observable<object> {
    return this._manualUpdateData;
  }


}
