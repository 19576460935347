import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {KzvCsvDataModelService} from '../../data-models/kzv-csv-data-model.service';

import {KzvService} from '../../services/kzv.service';
import {Papa} from "ngx-papaparse";

@Component({
  selector: 'app-csv-reader',
  templateUrl: './csv-reader.component.html',
  styleUrls: ['./csv-reader.component.scss']
})
export class CsvReaderComponent implements OnInit {

  @Output()
  public onReaderReady: EventEmitter<any> = new EventEmitter();

  constructor(private kzvCsv: KzvCsvDataModelService,
              private papa: Papa,
              private kzvService: KzvService) {
    this.onReaderReady.emit({
      test: 'test'
    });
  }

  ngOnInit() {
  }

  public emitCsvFile() {

    this.onReaderReady.emit({
      test: 'test'
    });
  }

  public openCsv($event): void {
    const file: File = $event.target.files[0];
    const fileReader: FileReader = new FileReader();
    this.kzvCsv.items = [];

    // csv exported from Excel don't parse correctly
    // https://github.com/mholt/PapaParse/issues/169#issuecomment-359165498
    fileReader.onloadend = (e) => {
      const result = (<FileReaderEventTarget>e.target).result;
      this.papa.parse(result, {
        encoding: 'ISO-8859-1',
        delimiter: ';',
        complete: (results) => {
          this.kzvCsv.items = this.removeEmptyRows(results.data);
          this.kzvService.importStatistics.overall.next(this.kzvCsv.items.length);
        }
      });

    };
    fileReader.readAsBinaryString(file);
  }

  private removeEmptyRows(importedRows) {
    const rows = [];
    for (let i = 0; i < importedRows.length; i++) {
      if (importedRows[i].length > 1) {
        rows.push(importedRows[i]);
      }
    }
    return rows;
  }
}

interface FileReaderEventTarget extends EventTarget {
  result: string;
}
