import {Businesshour} from './businesshour';
import {TableRow} from './table-row';
import {Image} from './image';

export class Surgery extends TableRow {

  private _title: string; // Frau
  private _type: string; // Zahnarztpraxis 2
  private _surname: string; // Forneck
  private _firstname: string; // Angela,
  private _street: string;
  private _zip: string;
  private _city: string;
  private _district: string;
  private _phone: string;
  private _fax: string;
  private _mail: string;
  private _web: string;
  private _uri: string;
  private _tags: string;
  private _languages: string;
  private _about: string;
  private _directions: string;
  private _hasdisabled: boolean;
  private _hasparkingspace: boolean;
  private _ispro: boolean;
  private _issleep: boolean;
  private _isimplant: boolean;
  private _isactive: boolean;
  private _isvisible: boolean;
  private _latitude: boolean;
  private _longitude: boolean;
  private _imexid: number;
  private _dentnetid: number;
  private _rankingscore: number;
  private _proquote: string;
  private _properson: string;
  private _viewuri: string;
  private _prolink: string;
  private _businesshours: Businesshour[] = [new Businesshour()];
  private _images: Image[] = [new Image()];

  constructor() {
    super();
  }

  get viewuri(): string {
    return this._viewuri;
  }

  set viewuri(value: string) {
    this._viewuri = value;
  }

  get images(): Image[] {
    return this._images;
  }

  set images(value: Image[]) {
    this._images = value;
  }

  get businesshours(): Businesshour[] {
    return this._businesshours;
  }

  set businesshours(value: Businesshour[]) {
    this._businesshours = value;
  }


  get rankingscore(): number {
    return this._rankingscore;
  }

  set rankingscore(value: number) {
    this._rankingscore = value;
  }

  get dentnetid(): number {
    return this._dentnetid;
  }

  set dentnetid(value: number) {
    this._dentnetid = value;
  }

  get imexid(): number {
    return this._imexid;
  }

  set imexid(value: number) {
    this._imexid = value;
  }

  get longitude(): boolean {
    return this._longitude;
  }

  set longitude(value: boolean) {
    this._longitude = value;
  }

  get latitude(): boolean {
    return this._latitude;
  }

  set latitude(value: boolean) {
    this._latitude = value;
  }

  get isvisible(): boolean {
    return this._isvisible;
  }

  set isvisible(value: boolean) {
    this._isvisible = value;
  }

  get isactive(): boolean {
    return this._isactive;
  }

  set isactive(value: boolean) {
    this._isactive = value;
  }

  get isimplant(): boolean {
    return this._isimplant;
  }

  set isimplant(value: boolean) {
    this._isimplant = value;
  }

  get issleep(): boolean {
    return this._issleep;
  }

  set issleep(value: boolean) {
    this._issleep = value;
  }

  get ispro(): boolean {
    return this._ispro;
  }

  set ispro(value: boolean) {
    this._ispro = value;
  }

  get hasparkingspace(): boolean {
    return this._hasparkingspace;
  }

  set hasparkingspace(value: boolean) {
    this._hasparkingspace = value;
  }

  get hasdisabled(): boolean {
    return this._hasdisabled;
  }

  set hasdisabled(value: boolean) {
    this._hasdisabled = value;
  }

  get directions(): string {
    return this._directions;
  }

  set directions(value: string) {
    this._directions = value;
  }

  get about(): string {
    return this._about;
  }

  set about(value: string) {
    this._about = value;
  }

  get languages(): string {
    return this._languages;
  }

  set languages(value: string) {
    this._languages = value;
  }

  get tags(): string {
    return this._tags;
  }

  set tags(value: string) {
    this._tags = value;
  }

  get uri(): string {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value;
  }

  get web(): string {
    return this._web;
  }

  set web(value: string) {
    this._web = value;
  }

  get mail(): string {
    return this._mail;
  }

  set mail(value: string) {
    this._mail = value;
  }

  get fax(): string {
    return this._fax;
  }

  set fax(value: string) {
    this._fax = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get district(): string {
    return this._district;
  }

  set district(value: string) {
    this._district = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get zip(): string {
    return this._zip;
  }

  set zip(value: string) {
    this._zip = value;
  }

  get street(): string {
    return this._street;
  }

  set street(value: string) {
    this._street = value;
  }

  get firstname(): string {
    return this._firstname;
  }

  set firstname(value: string) {
    this._firstname = value;
  }

  get surname(): string {
    return this._surname;
  }

  set surname(value: string) {
    this._surname = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get prolink(): string {
    return this._prolink;
  }

  set prolink(value: string) {
    this._prolink = value;
  }

  get properson(): string {
    return this._properson;
  }

  set properson(value: string) {
    this._properson = value;
  }

  get proquote(): string {
    return this._proquote;
  }

  set proquote(value: string) {
    this._proquote = value;
  }

}
