import {TableRow} from './table-row';

export class WorkingType extends TableRow {

  private _name: string;
  private _days: number[] = [];
  private _isChina: boolean;

  get isChina(): boolean {
    return this._isChina;
  }

  set isChina(value: boolean) {
    this._isChina = value;
  }

  get days(): number[] {
    return this._days;
  }

  set days(value: number[]) {
    this._days = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
