import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../classes/base-component';
import {ActivatedRoute} from '@angular/router';
import {DentnetCommunicatorService} from '../../../services/dentnet-communicator.service';
import {HealthinsuranceDataModelService} from '../../../data-models/healthinsurance-data-model.service';
import {AppConstants} from '../../../../constants/app-constants';
import {Healthinsurance} from '../../../classes/healthinsurance';

@Component({
  selector: 'app-insurance-add',
  templateUrl: './insurance-add.component.html',
  styleUrls: ['./insurance-add.component.scss']
})
export class InsuranceAddComponent extends BaseComponent implements OnInit {

  protected endpoint = AppConstants.INSURANCES;
  private _pzrList = [1, 2, 4];

  constructor(public communicationService: DentnetCommunicatorService,
              public activatedRoute: ActivatedRoute,
              public dataModel: HealthinsuranceDataModelService
  ) {
    super(communicationService, activatedRoute, dataModel);
    this.dataModel.buildFormGroup(new Healthinsurance().toObject());
    console.log(this.dataModel.formGroup);
  }

  ngOnInit() {
  }

  public setValue(key) {
    const control = this.dataModel.formGroup.get(key);
    if (control.value === 0) {
      control.setValue(1);
    } else {
      control.setValue(0);
    }
    console.log(control.value);
  }

  public checkValue(key) {
    const control = this.dataModel.formGroup.get(key);
    return control.value === 0 || !control.value;
  }

  get pzrList(): number[] {
    return this._pzrList;
  }

  set pzrList(value: number[]) {
    this._pzrList = value;
  }


}
