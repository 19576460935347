import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import {MessageService} from '../../../app/services/message.service';
import {Message} from '../../../app/classes/message';
import {PaginationComponent} from "../../../app/ui/pagination/pagination.component";

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit, AfterViewInit {

  @Output()
  imgChange = new EventEmitter();
  @Output()
  fileDataChange = new EventEmitter();

  @ViewChild('cropperMainElement', {static: false})
  cropperMainElement: ElementRef;

  private _preview: string;
  private _initialImageWidth: number;
  private _initialImageHeight: number;
  private _file;
  private _isDragging: Boolean = false;
  private _preferredImageDimensionsWidth: number;
  private _preferredImageDimensionsHeight: number;
  private _lastImagePositionX = 0;
  private _lastImagePositionY = 0;
  private _diffX = 0;
  private _diffY = 0;
  private _posMidX = 0;
  private _posMidY = 0;
  private _x = 0;
  private _y = 0;
  private _canvas;
  private _canvasContext;
  private _img: HTMLImageElement;
  private _imgWidth;
  private _imgHeight;
  private _overlay = false;
  private _downloadSrc;
  private _fileSize: number;
  private _position;
  private _haspreview = true;


  constructor(public messageService: MessageService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._canvas = this.cropperMainElement.nativeElement;
    this._canvasContext = this.cropperMainElement.nativeElement.getContext('2d');

    this._canvas.width = this._canvas.width + 300;
    this._canvas.height = this._canvas.height + 300;
  }


  @HostListener('mousedown', ['$event'])
  handleMouseDown(e) {
    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      this._isDragging = true;

      this._diffX = this._lastImagePositionX - e.clientX;
      this._diffY = this._lastImagePositionY - e.clientY;
    }
  }

  @HostListener('click', ['$event'])
  handleClick(e) {
    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      this._diffX = this._lastImagePositionX - e.clientX;
      this._diffY = this._lastImagePositionY - e.clientY;
    }
  }

  @HostListener('mouseup', ['$event'])
  handleMouseUp(e): void {
    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      this._isDragging = false;
    }
  }

  @HostListener('wheel', ['$event'])
  handleWheel(e): void {
    this.imgHeight = this.imgHeight + e.deltaY / 10;
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeave(e): void {
    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      this._isDragging = false;
    }
  }

  @HostListener('mouseout', ['$event'])
  handleMouseOut(e): void {
    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      this._isDragging = false;
    }
  }

  @HostListener('mousemove', ['$event'])
  handleMouseMove(e): void {

    const elementTarget = e.target;
    if (elementTarget.classList.contains('cropper')) {
      const x = e.clientX;
      const y = e.clientY;

      if (this._isDragging) {

        if (x < this._lastImagePositionX) {
          this._lastImagePositionX = x + Math.abs(this._diffX);
        } else {
          this._lastImagePositionX = x - Math.abs(this._diffX);
        }
        if (y < this._lastImagePositionY) {
          this._lastImagePositionY = y + Math.abs(this._diffY);
        } else {
          this._lastImagePositionY = y - Math.abs(this._diffY);
        }

        this.draw();
      }
    }
  }

  @Input()
  get preview(): string {
    return this._preview;
  }

  set preview(value: string) {
    this._preview = value;
  }

  @Input()
  get haspreview(): boolean {
    return this._haspreview;
  }

  set haspreview(value: boolean) {
    this._haspreview = value;
  }

  public readFile(e) {
    const file: File = e.target.files[0];
    console.log(file.size, this.fileSize);
    if (file.size >= this.fileSize) {
      // Todo Remove Method
      const mes = new Message();
      mes.text = 'Die Datei ist leider zu groß';
      mes.type = 'hint';
      this.messageService.addMessage(mes);
      //////////////////////////////////
      return false;
    }

    this.fileDataChange.emit(file.name);
    if (isNullOrUndefined(file)) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.getFileChange(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }

  @Input()
  get position() {
    return this._position;
  }

  set position(value) {
    this._position = value;
  }

  @Input()
  get fileSize() {
    return this._fileSize;
  }

  set fileSize(value) {
    this._fileSize = value;
  }

  @Input()
  get downloadSrc() {
    return this._downloadSrc;
  }

  set downloadSrc(value) {
    this._downloadSrc = value;
  }

  get imgHeight() {
    return this._imgHeight;
  }

  set imgHeight(value) {
    this._imgHeight = value;
    if (!isUndefined(this._img) && !isNull(this._img)) {
      this._img.height = this.imgHeight;
      this._img.width = this._getProportionalWidth();
      this._imgWidth = this._img.width;
      this.draw();
    }
  }

  get imgWidth() {
    return this._imgWidth;
  }

  set imgWidth(value) {
    this._imgWidth = value;
    if (!isUndefined(this._img) && !isNull(this._img)) {
      this._img.width = this._imgWidth;
      this._img.height = this._getProportionalHeight();
      this._imgHeight = this._img.height;
      this.draw();
    }
  }

  get lastImagePositionY(): number {
    return this._lastImagePositionY;
  }

  set lastImagePositionY(value: number) {
    this._lastImagePositionY = value;
    this.draw();
  }

  get lastImagePositionX(): number {
    return this._lastImagePositionX;
  }

  set lastImagePositionX(value: number) {
    this._lastImagePositionX = value;
    this.draw();
  }


  public get file() {
    return this._file;
  }

  public set file(file) {
    this._file = file;
  }

  private _getProportionalHeight(): number {
    return (this._initialImageHeight / this._initialImageWidth) * this._imgWidth;
  }

  private _getProportionalWidth(): number {
    return (this._initialImageWidth / this._initialImageHeight) * this._imgHeight;
  }

  public getFileChange(e) {
    this._img = new Image();
    this._img.src = e;
    this._img.onload = () => {

      this._initialImageWidth = this._img.width;
      this._initialImageHeight = this._img.height;
      this._imgWidth = this._img.width;
      this._imgHeight = this._img.height;
      this._lastImagePositionX = 0;
      this._lastImagePositionY = 0;

      this._calcPos();
      this.draw();
      this._overlay = true;

    };
  }

  private _calcPos(): void {
    this._posMidX = (this._canvas.width / 2 - this._preferredImageDimensionsWidth / 2);
    this._posMidY = (this._canvas.height / 2 - this._preferredImageDimensionsHeight / 2);
  }

  get overlay(): boolean {
    return this._overlay;
  }

  set overlay(value: boolean) {
    if (!isUndefined(this._img) && !isNull(this._img)) {
      this._overlay = value;
    }
  }

  @Input()
  public get preferredImageDimensionsWidth(): number {
    return this._preferredImageDimensionsWidth;
  }

  public set preferredImageDimensionsWidth(preferredImageDimensionWidth: number) {
    this._preferredImageDimensionsWidth = preferredImageDimensionWidth;
  }

  @Input()
  public get preferredImageDimensionsHeight(): number {
    return this._preferredImageDimensionsHeight;
  }

  public set preferredImageDimensionsHeight(preferredImageDimensionsHeight: number) {
    this._preferredImageDimensionsHeight = preferredImageDimensionsHeight;
  }

  public draw(): void {

    this._canvasContext.clearRect(0, 0, this._canvas.width, this._canvas.height);

    try {
      this._canvasContext.drawImage(
        this._img,
        this._lastImagePositionX,
        this._lastImagePositionY,
        this._img.width,
        this._img.height);
    } catch (e) {
      console.log(e);
    }

    this._canvasContext.fillStyle = 'rgba(0,0,0,0.6)';

    this._canvasContext.beginPath();
    this._canvasContext.rect(this._posMidX, this._posMidY, this._preferredImageDimensionsWidth, this._preferredImageDimensionsHeight);
    this._canvasContext.rect(this._canvas.width, 0, -this._canvas.width, this._canvas.height);
    this._canvasContext.fill();

    this._x = this._lastImagePositionX;
    this._y = this._lastImagePositionY;
  }


  public setImage() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.preferredImageDimensionsWidth;
    canvas.height = this.preferredImageDimensionsHeight;

    const image = new Image();
    image.src = this._canvas.toDataURL();

    image.onload = () => {
      context.drawImage(image, this._posMidX, this._posMidY, this._canvas.width, this._canvas.height, 0, 0, this._canvas.width, this._canvas.height);
      this._downloadSrc = canvas.toDataURL('image/png');
      this.imgChange.emit(this.downloadSrc);
      this.preview = this.downloadSrc;
      this._canvasContext.clearRect(0, 0, this._canvas.width, this._canvas.height);
      this._canvasContext.drawImage(
        image,
        this._posMidX,
        this._posMidY,
        this.preferredImageDimensionsWidth,
        this.preferredImageDimensionsHeight,
        this._posMidX,
        this._posMidY,
        this._preferredImageDimensionsWidth,
        this._preferredImageDimensionsHeight
      );
      this.overlay = false;
    };
  }

  public clearCanvas() {
    this._canvasContext.clearRect(0, 0, this._canvas.width, this._canvas.height);
  }

}

