import {TableRow} from './table-row';
import {Image} from './image';

export class Healthinsurance extends TableRow {

  private _name: string;
  private _uri: string;
  private _logo: string;
  private _pzr: number;
  private _isnulltarif = 0;
  private _isimplantate = 0;
  private _status: number;
  private _isikrone = 0;
  private _issleep = 0;
  private _ispregnant = 0;
  private _alternatives: string;
  private _refid: number;
  private _image: Image = new Image();

  get refid(): number {
    return this._refid;
  }

  set refid(value: number) {
    this._refid = value;
  }

  get alternatives(): string {
    return this._alternatives;
  }

  set alternatives(value: string) {
    this._alternatives = value;
  }

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get pzr(): number {
    return this._pzr;
  }

  set pzr(value: number) {
    this._pzr = value;
  }

  get logo(): string {
    return this._logo;
  }

  set logo(value: string) {
    this._logo = value;
  }

  get uri(): string {
    return this._uri;
  }

  set uri(value: string) {
    this._uri = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get image(): Image {
    return this._image;
  }

  set image(value: Image) {
    this._image = value;
  }

  get ispregnant(): number {
    return this._ispregnant;
  }

  set ispregnant(value: number) {
    this._ispregnant = value;
  }

  get issleep(): number {
    return this._issleep;
  }

  set issleep(value: number) {
    this._issleep = value;
  }

  get isikrone(): number {
    return this._isikrone;
  }

  set isikrone(value: number) {
    this._isikrone = value;
  }

  get isimplantate(): number {
    return this._isimplantate;
  }

  set isimplantate(value: number) {
    this._isimplantate = value;
  }

  get isnulltarif(): number {
    return this._isnulltarif;
  }

  set isnulltarif(value: number) {
    this._isnulltarif = value;
  }
}
